export default {
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_ACTIVITY_DOCUMENT_TYPES(state, activityDocumentTypes) {
    state.activityDocumentTypes = activityDocumentTypes
  },
  ADD_ACTIVITY_DOCUMENT_TYPE(state, activityDocumentType) {
    state.activityDocumentTypes.push(activityDocumentType)
  },
  UPDATE_ACTIVITY_DOCUMENT_TYPE(state, { activityDocumentType, index }) {
    Object.assign(state.activityDocumentTypes[index], activityDocumentType)
  },
  REMOVE_ACTIVITY_DOCUMENT_TYPE(state, index) {
    state.activityDocumentTypes.splice(index, 1)
  }
}
