import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"

import activity from "./activity"
import activityType from "./admin/activityType"
import auth from "./auth"
import config from "./config"
import cti from "./cti"
import datetime from "./datetime"
import dialogs from "./dialogs"
import mail from "./mail"
import mailAccount from "./admin/mailAccount"
import mailBox from "./admin/mailBox"
import monitor from "./monitor"
import template from "./admin/template"
import script from "./admin/script"
import scheduledMails from "./admin/scheduledMails"
import user from "./user"
import skill from "./skill"
import addressBook from "./admin/addressBook"
import setup from "./setup.js"
import activityTypeDocuments from "./admin/activityTypeDocuments"
import unusableContactMethods from "./admin/unusableContactMethods"

Vue.use(Vuex)

const vuexSessionStorage = new VuexPersistence({
  storage: window.sessionStorage,
  modules: ["auth"]
})

export default new Vuex.Store({
  modules: {
    activity,
    activityType,
    auth,
    config,
    cti,
    datetime,
    dialogs,
    mail,
    mailAccount,
    mailBox,
    monitor,
    template,
    script,
    scheduledMails,
    user,
    skill,
    addressBook,
    setup,
    activityTypeDocuments,
    unusableContactMethods
  },
  plugins: [vuexSessionStorage.plugin]
})
