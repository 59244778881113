export default {
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_UPDATING(state, value) {
    state.updating = value
  },
  SET_EMAIL_ACCOUNTS(state, value) {
    state.emailAccounts = value
  },
  ADD_EMAIL_ACCOUNT(state, value) {
    state.emailAccounts.push(value)
  },
  UPDATE_EMAIL_ACCOUNT(state, { emailAccount, index }) {
    Object.assign(state.emailAccounts[index], emailAccount)
  },
  REMOVE_EMAIL_ACCOUNT(state, index) {
    state.emailAccounts.splice(index, 1)
  },
  SET_POLLED_ACCOUNTS(state, value) {
    state.polledAccounts = value
  }
}
