export default {
  loading: false,
  updating: false,
  templates: [],
  templateApplications: [],
  allTemplateApplications: [],
  languages: [
    {
      languageId: "ES",
      language: "Español"
    },
    {
      languageId: "EN",
      language: "Inglés"
    },
    {
      languageId: "DE",
      language: "Alemán"
    },
    {
      languageId: "FR",
      language: "Francés"
    },
    {
      languageId: "IT",
      language: "Italiano"
    },
    {
      languageId: "PT",
      language: "Portugués"
    }
  ]
}
