export default {
  SET_SCRIPT_FILTER(state, value) {
    state.scripts = value
  },
  SET_SCRIPTS(state, value) {
    state.scripts = value
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_UPDATING(state, value) {
    state.updating = value
  },
  ADD_SCRIPT(state, value) {
    state.scripts.push(value)
  },
  UPDATE_SCRIPT(state, { script, index }) {
    Object.assign(state.scripts[index], script)
  },
  REMOVE_SCRIPT(state, index) {
    state.scripts.splice(index, 1)
  },
  UPLOAD_FILE(state, { index, script }) {
    //state.scripts[index].scriptUri = script.scriptUri
    Object.assign(state.scripts[index], script)
  }
}
