import Vue from "vue"

const getScheduledMails = async ({ commit }, serviceId) => {
  try {
    commit("SET_LOADING", true)
    const res = await Vue.axios.get(`/rest/v2/${serviceId}/activities/scheduledemails`)
    commit("SET_SCHEDULED_MAILS", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const updateScheduledMail = async ({ commit }, { scheduledMail, index }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.put(
      `/rest/v2/${scheduledMail.serviceId}/activities/${scheduledMail.activityId}/scheduledemails/${scheduledMail.scheduledEmailId}`,
      scheduledMail
    )
    commit("UPDATE_SCHEDULED_MAIL", { scheduledMail: res.data, index })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const deleteScheduledMail = async ({ commit }, { scheduledMail, index }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.delete(
      `/rest/v2/${scheduledMail.serviceId}/activities/${scheduledMail.activityId}/scheduledemails/${scheduledMail.scheduledEmailId}`
    )
    commit("REMOVE_SCHEDULED_MAIL", index)
    return Promise.resolve(scheduledMail)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

export default {
  getScheduledMails,
  updateScheduledMail,
  deleteScheduledMail
}
