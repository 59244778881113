import Vue from "vue"
import VueRouter from "vue-router"
import store from "@/store/index"

Vue.use(VueRouter)

const routes = [
  {
    path: "/(login)?",
    name: "Login",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Login"),
    meta: {
      noAuthRequired: true
    }
  },
  {
    path: "/myhome",
    name: "Home",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Home")
  },
  // {
  //   path: "/mail",
  //   name: "Mail",
  //   component: () => import(/* webpackChunkName: "about" */ "@/views/Mail")
  // },

  {
    path: "/cti",
    name: "CTI",
    component: () => import(/* webpackChunkName: "about" */ "@/views/CTI")
  },
  {
    path: "/chat",
    name: "Chat",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Chat")
  },
  {
    path: "/task",
    name: "Task",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Task")
  },
  {
    path: "/monitor",
    name: "Monitor",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Monitor")
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Admin"),
    children: [
      {
        path: "/admin/mail",
        components: {
          adminViews: () => import(/* webpackChunkName: "about" */ "@/views/admin/mail/Mail")
        }
      },
      {
        path: "/admin/scheduledmails",
        components: {
          adminViews: () =>
            import(/* webpackChunkName: "about" */ "@/views/admin/mail/ScheduledMails")
        }
      },
      {
        path: "/admin/templates",
        components: {
          adminViews: () => import(/* webpackChunkName: "about" */ "@/views/admin/Templates")
        }
      },
      {
        path: "/admin/scripts",
        components: {
          adminViews: () => import(/* webpackChunkName: "about" */ "@/views/admin/Scripts")
        }
      },
      {
        path: "/admin/activitytypes",
        components: {
          adminViews: () =>
            import(/* webpackChunkName: "about" */ "@/views/admin/ActivityTypesMenu")
        }
      },
      {
        path: "/admin/unusablecontactmethods",
        components: {
          adminViews: () =>
            import(/*webpackChunkName: "about" */ "@/views/admin/UnusableContactMethods.vue")
        }
      }
    ]
  },
  {
    path: "/devel",
    name: "Dev",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Dev")
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import(/* webpackChunkName: "about" */ "@/views/NotFound")
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const params = new URLSearchParams(window.location.search)
  if (!to.meta.noAuthRequired && !store.state.auth.isAuth && !params.has("token")) {
    const pathname =
      window.location.pathname + (window.location.search ? window.location.search : "")
    next({
      name: "Login",
      query: { pathname }
    })
  } else if (to.name == "login" && store.state.auth.isAuth) {
    next({
      name: "Home"
    })
  } else {
    next()
  }
})

export default router
