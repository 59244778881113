export default {
  loading: false,
  lastRefresh: null,
  monitorMailBoxes: [],
  detailTypes: [
    {
      id: "all",
      description: "Entrantes/Salientes"
    },
    {
      id: "inbound",
      description: "Entrantes"
    },
    {
      id: "outbound",
      description: "Salientes (en blanco)"
    }
  ]
}
