export default {
  loading: false,
  updating: false,
  loadingContent: false,
  mails: [],
  drafts: [],
  mailCount: 0,
  unassignedMailCount: 0,
  mailBoxes: [],
  emailAccounts: [],
  mailContent: null,
  mail: null,
  priorities: [
    {
      id: 10,
      description: "Top",
      color: "red lighten-3"
    },
    {
      id: 20,
      description: "High",
      color: "orange lighten-2"
    },
    {
      id: 30,
      description: "Medium",
      color: "yellow lighten-2"
    },
    {
      id: 40,
      description: "Low",
      color: "lime lighten-2"
    }
  ]
}
