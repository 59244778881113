import Vue from "vue"
import axios from "axios"
import VueAxios from "vue-axios"
import store from "@/store/index"

axios.defaults.baseURL = process.env.VUE_APP_API_CRM_URL
axios.defaults.headers.common["Accept"] = "application/json"
axios.defaults.timeout = 1000 * 30
axios.defaults.paramsSerializer = {
  indexes: null
}

const API_CTI_URL = process.env.VUE_APP_API_CTI_URL
const API_DESK_URL = process.env.VUE_APP_API_DESK_URL
const API_SCRIPTING_URL = process.env.VUE_APP_API_SCRIPTS_URL
const API_DOCS_URL = process.env.VUE_APP_API_DOCS_URL

axios.interceptors.request.use(config => {
  const token = store.state.auth.token
  const isAuth = config.url.indexOf("/auth/login") != -1 && ["get", "post"].includes(config.method)
  if (!isAuth && token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  if (config.url.startsWith("/api-cti") || config.url.startsWith("/cti-genesysgcc")) {
    config.baseURL = API_CTI_URL
    config.url = config.url.replace("/api-cti", "")
  }

  if (config.url.startsWith("/api-desk")) {
    config.url = config.url.replace("/api-desk", "")
    config.baseURL = API_DESK_URL
  }

  if (config.url.startsWith("/api-scripting")) {
    config.url = config.url.replace("/api-scripting", "")
    config.baseURL = API_SCRIPTING_URL
  }

  if (config.url.startsWith("/api-docs")) {
    config.url = config.url.replace("/api-docs", "")
    config.baseURL = API_DOCS_URL
  }
  return config
})

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.code === "ECONNABORTED") {
      error.message = `Tiempo de espera superado: ${
        error.config.timeout / 1000
      } segundos\n${error.config.method.toUpperCase()} - ${error.config.baseURL}${error.config.url}`
    }
    if (
      error.response &&
      error.response.status == 401 &&
      error.response.config.url.indexOf("/rest/auth/login") == -1
    ) {
      store.dispatch("auth/resetAuth", "Sesión caducada")
    }
    return Promise.reject(error)
  }
)

Vue.use(VueAxios, axios)
