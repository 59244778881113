import Vue from "vue"

export default {
  namespaced: true,
  state: {
    loading: false,
    skills: [],
    stateSkills: []
  },
  getters: {
    skillName: state => skillId =>
      state.skills.find(skill => skill.skillId === skillId).skill || {},
    stateSkillName: state => skillId =>
      state.stateSkills.find(skill => skill.skillId === skillId)?.skill || "-"
  },
  mutations: {
    SET_SKILLS(state, values) {
      state.skills = values
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_STATE_SKILLS(state, values) {
      state.stateSkills = values
    }
  },
  actions: {
    async getSkills({ commit }, { serviceId, skillType }) {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.get(`/api-desk/rest/skills/${skillType}/service/${serviceId}`)
        commit("SET_SKILLS", res.data)
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },
    async getStateSkills({ commit }, serviceId) {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.get(`/api-desk/rest/skills/STAT/service/${serviceId}`)
        commit("SET_STATE_SKILLS", res.data)
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    }
  }
}
