import Vue from "vue"

const getMailBoxesMonitorByService = async ({ commit }, serviceId) => {
  try {
    commit("SET_LOADING", true)
    const res = await Vue.axios.get(`/rest/mailboxes/${serviceId}/monitor`)
    commit("UPDATE_LAST_REFRESH")
    commit("SET_MONITOR_MAILBOXES", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

export default {
  getMailBoxesMonitorByService
}
