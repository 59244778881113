import Vue from "vue"

const getAddresses = async ({ commit }, serviceId) => {
  try {
    commit("SET_LOADING", true)
    const res = await Vue.axios.get(`/rest/emails/addresses/${serviceId}/emailaddresses`)
    commit("SET_ADDRESSES", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const addAddress = async ({ commit }, { emailAddress, serviceId }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.post(
      `/rest/emails/addresses/${serviceId}/emailaddresses`,
      emailAddress
    )
    commit("ADD_ADDRESS", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const updateAddress = async ({ commit }, { emailAddress, index, serviceId }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.put(
      `/rest/emails/addresses/${serviceId}/emailaddresses/${emailAddress.addressId}`,
      emailAddress
    )
    commit("UPDATE_ADDRESS", { emailAddress: res.data, index })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const deleteAddress = async ({ commit }, { emailAddress, index, serviceId }) => {
  try {
    commit("SET_UPDATING", true)
    await Vue.axios.delete(
      `/rest/emails/addresses/${serviceId}/emailaddresses/${emailAddress.addressId}`
    )
    commit("REMOVE_ADDRESS", index)
    return Promise.resolve(emailAddress)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const getMailGroups = async ({ commit }, serviceId) => {
  try {
    commit("SET_LOADING", true)
    const res = await Vue.axios.get(`/rest/emails/addresses/${serviceId}/emailgroups`)
    commit("SET_MAIL_GROUPS", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const addMailGroup = async ({ commit }, { serviceId, emailGroup }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.post(`/rest/emails/addresses/${serviceId}/emailgroups`, emailGroup)
    commit("ADD_MAIL_GROUP", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const updateMailGroup = async ({ commit }, { serviceId, emailGroup, index }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.put(
      `/rest/emails/addresses/${serviceId}/emailgroups/${emailGroup.groupId}`,
      emailGroup
    )
    commit("UPDATE_MAIL_GROUP", { emailGroup: res.data, index })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const deleteMailGroup = async ({ commit }, { emailGroup, index, serviceId }) => {
  try {
    commit("SET_UPDATING", true)
    await Vue.axios.delete(`/rest/emails/addresses/${serviceId}/emailgroups/${emailGroup.groupId}`)
    commit("REMOVE_MAIL_GROUP", index)
    return Promise.resolve(emailGroup)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const getAddressBooks = async ({ commit }, serviceId) => {
  try {
    commit("SET_LOADING", true)
    const res = await Vue.axios.get(`/rest/emails/addresses/${serviceId}/addressbooks`)
    commit("SET_ADDRESS_BOOKS", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const addAddressBook = async ({ commit }, { serviceId, addressBook }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.post(
      `/rest/emails/addresses/${serviceId}/addressbooks`,
      addressBook
    )
    commit("ADD_ADDRESS_BOOK", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const updateAddressBook = async ({ commit }, { serviceId, addressBook, index }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.put(
      `/rest/emails/addresses/${serviceId}/addressbooks/${addressBook.bookId}`,
      addressBook
    )
    commit("UPDATE_ADDRESS_BOOK", { addressBook: res.data, index })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const deleteAddressBook = async ({ commit }, { serviceId, index, addressBook }) => {
  try {
    commit("SET_UPDATING", true)
    await Vue.axios.delete(`/rest/emails/addresses/${serviceId}/addressbooks/${addressBook.bookId}`)
    commit("REMOVE_ADDRESS_BOOK", index)
    return Promise.resolve(addressBook)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}
export default {
  getAddresses,
  addAddress,
  updateAddress,
  deleteAddress,
  getMailGroups,
  addMailGroup,
  updateMailGroup,
  deleteMailGroup,
  getAddressBooks,
  addAddressBook,
  updateAddressBook,
  deleteAddressBook
}
