export default {
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_UPDATING(state, value) {
    state.updating = value
  },
  SET_TEMPLATES(state, value) {
    state.templates = value
  },
  SET_TEMPLATE_APPLICATIONS(state, value) {
    state.templateApplications = value
  },
  SET_ALL_TEMPLATE_APPLICATIONS(state, value) {
    state.allTemplateApplications = value
  },
  ADD_TEMPLATE(state, value) {
    state.templates.push(value)
  },
  UPDATE_TEMPLATE(state, { template, index }) {
    Object.assign(state.templates[index], template)
  },
  REMOVE_TEMPLATE(state, index) {
    state.templates.splice(index, 1)
  },
  ADD_TEMPLATE_LANGUAGE(state, { language, index }) {
    state.templates[index].languages.push(language)
  },
  UPDATE_TEMPLATE_LANGUAGE(state, { langIndex, language, index }) {
    Object.assign(state.templates[index].languages[langIndex], language)
  },
  DELETE_TEMPLATE_LANGUAGE(state, { langIndex, index }) {
    state.templates[index].languages.splice(langIndex, 1)
  },
  ADD_TEMPLATE_SERVICE(state, { templateService, index }) {
    state.templates[index].services.push(templateService)
  },
  DELETE_TEMPLATE_SERVICE(state, { serviceIndex, index }) {
    state.templates[index].services.splice(serviceIndex, 1)
  }
}
