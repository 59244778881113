export default {
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_UPDATING(state, value) {
    state.updating = value
  },
  SET_SCHEDULED_MAILS(state, value) {
    state.scheduledMails = value
  },
  UPDATE_SCHEDULED_MAIL(state, { scheduledMail, index }) {
    Object.assign(state.scheduledMails[index], scheduledMail)
  },
  REMOVE_SCHEDULED_MAIL(state, index) {
    state.scheduledMails.splice(index, 1)
  }
}
