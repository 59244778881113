import Vue from "vue"

const getEmailAccounts = async ({ commit }, serviceId) => {
  try {
    commit("SET_LOADING", true)
    const params = { serviceId }
    const res = await Vue.axios.get("/rest/v2/emailaccounts", { params })
    commit("SET_EMAIL_ACCOUNTS", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const addEmailAccount = async ({ commit }, emailAccount) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.post("/rest/v2/emailaccounts", emailAccount)
    commit("ADD_EMAIL_ACCOUNT", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const updateEmailAccount = async ({ commit }, { emailAccount, index }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.put(
      `/rest/v2/emailaccounts/${emailAccount.emailAccountId}`,
      emailAccount
    )
    commit("UPDATE_EMAIL_ACCOUNT", { emailAccount: res.data, index })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const deleteAccount = async ({ commit }, { emailAccount, index }) => {
  try {
    commit("SET_UPDATING", true)
    await Vue.axios.delete(`/rest/v2/emailaccounts/${emailAccount.emailAccountId}`)
    commit("REMOVE_EMAIL_ACCOUNT", index)
    return Promise.resolve(emailAccount)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const testAccount = async ({ commit }, emailAccountId) => {
  try {
    commit("SET_LOADING", true)
    const res = await Vue.axios.get(`/rest/v2/emailaccounts/${emailAccountId}/test`)
    //commit("SET_TEST_ACCOUNTS", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const getPolledAccounts = async ({ commit }, monitor) => {
  try {
    commit("SET_LOADING", true)
    const res = await Vue.axios.get(`${monitor.host}${monitor.action}`)
    commit("SET_POLLED_ACCOUNTS", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const runPolling = async ({ commit }, monitor) => {
  try {
    commit("SET_LOADING", true)
    const res = await Vue.axios.post(`${monitor.host}${monitor.action}`)
    commit("SET_POLLED_ACCOUNTS", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const shutdownPolling = async ({ commit }, monitor) => {
  try {
    commit("SET_LOADING", true)
    const res = await Vue.axios.delete(`${monitor.host}${monitor.action}`)
    commit("SET_POLLED_ACCOUNTS", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

export default {
  getEmailAccounts,
  addEmailAccount,
  updateEmailAccount,
  deleteAccount,
  testAccount,
  getPolledAccounts,
  runPolling,
  shutdownPolling
}
