import Vue from "vue"

export default {
  namespaced: true,
  state: {
    loading: false,
    updating: false,
    activityTypes: [],
    statusTypes: [],
    status: [],
    activityTypeFieldProps: [],
    queues: [],
    statusTypeGroups: [
      {
        id: "I",
        description: "Inicial",
        statuses: ["I"],
        icon: "mdi-minus",
        color: "white"
      },
      {
        id: "G",
        description: "En progreso",
        statuses: ["G"],
        icon: "mdi-play",
        color: "success"
      },
      {
        id: "S",
        description: "Suspendido",
        statuses: ["S"],
        icon: "mdi-pause",
        color: "warning"
      },
      {
        id: "F",
        description: "Finalizado",
        statuses: ["P", "N", "O"],
        icon: "mdi-stop",
        color: "warning"
      }
    ],
    fieldTypes: [
      {
        name: "Texto",
        fieldType: "TEXT"
      },
      {
        name: "Fecha",
        fieldType: "DATE"
      },
      {
        name: "Lista",
        fieldType: "LIST"
      },
      {
        name: "Multi",
        fieldType: "MULTI"
      },
      {
        name: "Selector",
        fieldType: "SELECTOR"
      },
      {
        name: "Textarea",
        fieldType: "TEXTAREA"
      },
      {
        name: "Enlace",
        fieldType: "LINK"
      },
      {
        name: "Recordatorio",
        fieldType: "LABEL"
      },
      {
        name: "HTML",
        fieldType: "HTML"
      },
      {
        name: "Búsqueda de usuarios",
        fieldType: "USERS"
      },
      {
        name: "Tabla",
        fieldType: "GRID"
      },
      {
        name: "Pestaña",
        fieldType: "TAB"
      },
      {
        name: "Fecha/hora",
        fieldType: "DATETIME"
      },
      {
        name: "Numérico",
        fieldType: "NUMBER"
      },
      {
        name: "Spacer",
        fieldType: "SPACER"
      },
      {
        name: "Oculto",
        fieldType: "HIDDEN"
      }
    ]
  },
  getters: {
    statusTypeGroup: state => statusType =>
      state.statusTypeGroups.find(st => st.statuses.includes(statusType)) || {},
    fieldType: state => fieldType => state.fieldTypes.find(field => field.fieldType === fieldType),
    activityTypeStatuses: state => activityTypeId =>
      (state.activityTypes.find(at => at.activityTypeId === activityTypeId) || {}).statuses || []
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_UPDATING(state, value) {
      state.updating = value
    },
    ADD_ACTIVITY_TYPE(state, value) {
      state.activityTypes.push(value)
    },
    UPDATE_ACTIVITY_TYPE(state, { activityType, index }) {
      Object.assign(state.activityTypes[index], activityType)
    },
    SET_ACTIVITY_TYPES(state, value) {
      state.activityTypes = value
    },
    REMOVE_ACTIVITY_TYPE(state, index) {
      state.activityTypes.splice(index, 1)
    },
    SET_STATUS_TYPES(state, value) {
      state.statusTypes = value
    },
    ADD_ACTIVITY_TYPE_STATUS(state, { activityTypeStatus, index }) {
      state.activityTypes[index].statuses.push(activityTypeStatus)
    },
    UPDATE_ACTIVITY_TYPE_STATUS(state, { activityTypeStatus, statusIndex, index }) {
      Object.assign(state.activityTypes[index].statuses[statusIndex], activityTypeStatus)
    },
    DELETE_ACTIVITY_TYPE_STATUS(state, { statusIndex, index }) {
      state.activityTypes[index].statuses.splice(statusIndex, 1)
    },
    SET_ACTIVITY_TYPE_FIELD_PROP(state, value) {
      state.activityTypeFieldProps = value
    },
    ADD_ACTIVITY_TYPE_FIELD_PROP(state, value) {
      state.activityTypeFieldProps.push(value)
    },
    UPDATE_ACTIVITY_TYPE_FIELD_PROP(state, { activityTypeFieldProp, index }) {
      Object.assign(state.activityTypeFieldProps[index], activityTypeFieldProp)
    },
    REMOVE_ACTIVITY_TYPE_FIELD_PROP(state, index) {
      state.activityTypeFieldProps.splice(index, 1)
    },
    ADD_ACTIVITY_TYPE_FIELD_PROP_TYPE(state, { activityTypeFieldProp, activityTypeIndex }) {
      state.activityTypes[activityTypeIndex].activityTypeFieldProps.push(activityTypeFieldProp)
    },

    UPDATE_ACTIVITY_TYPE_FIELD_PROP_TYPE(state, { activityTypeFieldProps, index }) {
      state.activityTypes[index].activityTypeFieldProps = structuredClone(activityTypeFieldProps)
    },
    REMOVE_ACTIVITY_TYPE_FIELD_PROP_TYPE(state, { fieldIndex, index }) {
      state.activityTypes[index].activityTypeFieldProps.splice(fieldIndex, 1)
    },
    ADD_ACTIVITY_TYPE_SKILL(state, { activityType, index, skillId }) {
      state.activityTypes[index].skills.push(activityType.skills.find(s => s.skillId === skillId))
    },
    ADD_ACTIVITY_TYPE_QUEUE(state, { index, queue }) {
      state.activityTypes[index].activityTypeQueues.push(queue)
    },
    DELETE_ACTIVITY_TYPE_SKILL(state, { skillIndex, index }) {
      state.activityTypes[index].skills.splice(skillIndex, 1)
    },
    SET_QUEUES(state, value) {
      state.queues = value
    },
    REMOVE_ACTIVITY_TYPE_QUEUE(state, { queueIndex, index }) {
      state.activityTypes[index].activityTypeQueues.splice(queueIndex, 1)
    },
    ADD_ACTIVITY_TYPE_CALLBACK_TEMPLATE(state, { index, callbackTemplate }) {
      state.activityTypes[index].callbackTemplates.push(callbackTemplate)
    },
    REMOVE_ACTIVITY_TYPE_CALLBACK_TEMPLATE(state, { index, callbackTemplateIndex }) {
      state.activityTypes[index].callbackTemplates.splice(callbackTemplateIndex, 1)
    },
    UPDATE_ACTIVITY_TYPE_CALLBACK_TEMPLATE(
      state,
      { activityType, index, callbackTemplateIndex, callbackTemplate }
    ) {
      const updatedCallbackTemplate = activityType.callbackTemplates.find(
        ct => ct.callbackTemplateId === callbackTemplate.callbackTemplateId
      )
      Object.assign(
        state.activityTypes[index].callbackTemplates[callbackTemplateIndex],
        updatedCallbackTemplate
      )
    },
    ADD_ACTIVITY_TYPE_DOCUMENT_TYPE(state, { activityTypeDocumentType, index }) {
      state.activityTypes[index].documentTypes.push(activityTypeDocumentType)
    },
    UPDATE_ACTIVITY_TYPE_DOCUMENT_TYPE(
      state,
      { activityTypeDocumentType, index, documentTypeIndex }
    ) {
      Object.assign(
        state.activityTypes[index].documentTypes[documentTypeIndex],
        activityTypeDocumentType
      )
    },
    REMOVE_ACTIVITY_TYPE_DOCUMENT_TYPE(state, { index, documentTypeIndex }) {
      state.activityTypes[index].documentTypes.splice(documentTypeIndex, 1)
    },
    ADD_ACTIVITY_TYPE_SUB_ACTIVITY_TYPE(state, { subActivityType, index }) {
      state.activityTypes[index].subActivityTypes.push(subActivityType)
    },
    REMOVE_ACTIVITY_TYPE_SUB_ACTIVITY_TYPE(state, { index, subActivityTypeIndex }) {
      state.activityTypes[index].subActivityTypes.splice(subActivityTypeIndex, 1)
    }
  },
  actions: {
    clearActivityTypes({ commit }) {
      commit("SET_ACTIVITY_TYPES", [])
    },

    getActivityTypes: async ({ commit }, serviceId) => {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.get(`/rest/v2/${serviceId}/activitytypes`)
        commit("SET_ACTIVITY_TYPES", res.data)
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },
    addActivityType: async ({ commit }, activityType) => {
      try {
        commit("SET_UPDATING", true)
        const res = await Vue.axios.post("/rest/activitytypes", activityType)
        commit("ADD_ACTIVITY_TYPE", res.data)
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_UPDATING", false)
      }
    },
    updateActivityType: async ({ commit }, { activityType, index }) => {
      try {
        commit("SET_UPDATING", true)
        const res = await Vue.axios.put(
          `/rest/activitytypes/${activityType.activityTypeId}`,
          activityType
        )
        commit("UPDATE_ACTIVITY_TYPE", { activityType: res.data, index })
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_UPDATING", false)
      }
    },
    deleteActivityType: async ({ commit }, { activityType, index }) => {
      try {
        commit("SET_UPDATING", true)
        await Vue.axios.delete(`/rest/activitytypes/${activityType.activityTypeId}`)
        commit("REMOVE_ACTIVITY_TYPE", index)
        return Promise.resolve(activityType)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_UPDATING", false)
      }
    },
    getStatusTypes: async ({ commit }) => {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.get("/rest/activitytypes/statuses/types")
        commit("SET_STATUS_TYPES", res.data)
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },
    addStatusType: async ({ commit }, { activityTypeStatus, index }) => {
      try {
        commit("SET_UPDATING", true)
        const res = await Vue.axios.post(
          `/rest/activitytypes/${activityTypeStatus.activityTypeId}/statuses`,
          activityTypeStatus
        )
        commit("ADD_ACTIVITY_TYPE_STATUS", { activityTypeStatus: res.data, index })
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_UPDATING", false)
      }
    },
    updateStatusType: async ({ commit }, { activityTypeStatus, index, statusIndex }) => {
      try {
        commit("SET_UPDATING", true)
        const res = await Vue.axios.put(
          `/rest/activitytypes/${activityTypeStatus.activityTypeId}/statuses/${activityTypeStatus.status}`,
          activityTypeStatus
        )
        commit("UPDATE_ACTIVITY_TYPE_STATUS", { activityTypeStatus: res.data, index, statusIndex })
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_UPDATING", false)
      }
    },
    deleteStatusType: async ({ commit }, { activityTypeStatus, index, statusIndex }) => {
      try {
        commit("SET_UPDATING", true)
        await Vue.axios.delete(
          `/rest/activitytypes/${activityTypeStatus.activityTypeId}/statuses/${activityTypeStatus.status}`
        )
        commit("DELETE_ACTIVITY_TYPE_STATUS", { index, statusIndex })
        return Promise.resolve(activityTypeStatus)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_UPDATING", false)
      }
    },
    getActivityTypeFieldProp: async ({ commit }, serviceId) => {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.get(`/rest/activitytypes/fieldproperties/service/${serviceId}`)
        commit("SET_ACTIVITY_TYPE_FIELD_PROP", res.data)
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },
    addActivityTypeFieldProp: async ({ commit }, activityTypeFieldProp) => {
      try {
        commit("SET_UPDATING", true)
        const res = await Vue.axios.post(
          "/rest/activitytypes/fieldproperties",
          activityTypeFieldProp
        )
        commit("ADD_ACTIVITY_TYPE_FIELD_PROP", res.data)
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_UPDATING", false)
      }
    },
    updateActivityTypeFieldProp: async ({ commit }, { activityTypeFieldProp, index }) => {
      try {
        commit("SET_UPDATING", true)
        const res = await Vue.axios.put(
          `/rest/activitytypes/fieldproperties/${activityTypeFieldProp.fieldId}`,
          activityTypeFieldProp
        )
        commit("UPDATE_ACTIVITY_TYPE_FIELD_PROP", { activityTypeFieldProp: res.data, index })
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_UPDATING", false)
      }
    },
    deleteActivityTypeFieldProp: async ({ commit }, { fieldId, index }) => {
      try {
        commit("SET_UPDATING", true)
        await Vue.axios.delete(`/rest/activitytypes/fieldproperties/${fieldId}`)
        commit("REMOVE_ACTIVITY_TYPE_FIELD_PROP", index)
        return Promise.resolve(fieldId)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_UPDATING", false)
      }
    },

    updateActivityTypeFieldPropType: async (
      { commit },
      { activityTypeFieldProps, index, fieldIndex, activityType }
    ) => {
      try {
        commit("SET_UPDATING", true)
        const res = await Vue.axios.put(
          `/rest/v2/${activityType.serviceId}/activitytypes/${activityType.activityTypeId}/fieldproperties`,
          activityTypeFieldProps
        )
        commit("UPDATE_ACTIVITY_TYPE_FIELD_PROP_TYPE", {
          activityTypeFieldProps: res.data,
          index
        })
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_UPDATING", false)
      }
    },

    addSkillToActivityType: async ({ commit }, { activityTypeId, skillId, index, serviceId }) => {
      try {
        commit("SET_UPDATING", true)
        const res = await Vue.axios.post(
          `/rest/v2/${serviceId}/activitytypes/${activityTypeId}/skills/${skillId}`
        )
        commit("ADD_ACTIVITY_TYPE_SKILL", { activityType: res.data, index, skillId })
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_UPDATING", false)
      }
    },
    deleteSkillFromActivityType: async (
      { commit },
      { activityTypeId, skillId, index, skillIndex, skill, serviceId }
    ) => {
      try {
        commit("SET_UPDATING", true)
        const res = await Vue.axios.delete(
          `/rest/v2/${serviceId}/activitytypes/${activityTypeId}/skills/${skillId}`
        )
        commit("DELETE_ACTIVITY_TYPE_SKILL", { index, skillIndex })
        return Promise.resolve(skill)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_UPDATING", false)
      }
    },
    getQueues: async ({ commit }, serviceId) => {
      try {
        commit("SET_LOADING", true)
        const params = {
          typeAddress: "ACD",
          pbxId: "GENESYSGCC",
          serviceId: serviceId
        }

        const res = await Vue.axios.get("/api-cti/rest/addresses", { params })
        commit("SET_QUEUES", res.data)
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },
    addQueueToActivityType: async ({ commit }, { activityType, queue, index }) => {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.post(
          `/rest/v2/${activityType.serviceId}/activitytypes/${activityType.activityTypeId}/queues/${queue.pbxId}/${queue.address}`
        )
        commit("ADD_ACTIVITY_TYPE_QUEUE", { activityType: res.data, index, queue })
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },
    deleteQueueFromActivityType: async ({ commit }, { activityType, queue, index, queueIndex }) => {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.delete(
          `/rest/v2/${activityType.serviceId}/activitytypes/${activityType.activityTypeId}/queues/${queue.pbxId}/${queue.address}`
        )
        commit("REMOVE_ACTIVITY_TYPE_QUEUE", { index, queueIndex })
        return Promise.resolve(queue)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },
    addCallbackTemplateToActivityType: async (
      { commit },
      { activityType, callbackTemplate, index }
    ) => {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.post(
          `/rest/v2/${activityType.serviceId}/activitytypes/${activityType.activityTypeId}/callbacktemplate`,
          callbackTemplate
        )
        commit("ADD_ACTIVITY_TYPE_CALLBACK_TEMPLATE", {
          callbackTemplate: res.data,
          index
        })
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },
    updateActivityTypeCallbackTemplate: async (
      { commit },
      { activityType, index, callbackTemplateIndex, callbackTemplate }
    ) => {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.put(
          `/rest/v2/${activityType.serviceId}/activitytypes/${activityType.activityTypeId}/callbacktemplate/${callbackTemplate.callbackTemplateId}`,
          callbackTemplate
        )
        commit("UPDATE_ACTIVITY_TYPE_CALLBACK_TEMPLATE", {
          callbackTemplate: res.data,
          index,
          callbackTemplateIndex
        })
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },
    deleteCallbackTemplateFromActivityType: async (
      { commit },
      { activityType, index, callbackTemplateIndex, callbackTemplate }
    ) => {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.delete(
          `/rest/v2/${activityType.serviceId}/activitytypes/${activityType.activityTypeId}/callbacktemplate/${callbackTemplate.callbackTemplateId}`
        )
        commit("REMOVE_ACTIVITY_TYPE_CALLBACK_TEMPLATE", { index, callbackTemplateIndex })
        return Promise.resolve(callbackTemplate)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },
    addActivityTypeDocumentType: async (
      { commit },
      { activityTypeDocumentType, index, activityType }
    ) => {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.post(
          `/rest/v2/${activityType.serviceId}/activitytypes/${activityType.activityTypeId}/documenttypes`,
          activityTypeDocumentType
        )
        commit("ADD_ACTIVITY_TYPE_DOCUMENT_TYPE", { activityTypeDocumentType: res.data, index })
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },
    updateActivityTypeDocumentType: async (
      { commit },
      { activityType, activityTypeDocumentType, index, documentTypeIndex }
    ) => {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.put(
          `/rest/v2/${activityType.serviceId}/activitytypes/${activityType.activityTypeId}/documenttypes/${activityTypeDocumentType.documentTypeId}`,
          activityTypeDocumentType
        )
        commit("UPDATE_ACTIVITY_TYPE_DOCUMENT_TYPE", {
          activityTypeDocumentType: res.data,
          index,
          documentTypeIndex
        })
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },
    deleteActivityTypeDocumentType: async (
      { commit },
      { activityType, activityTypeDocumentType, index, documentTypeIndex }
    ) => {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.delete(
          `/rest/v2/${activityType.serviceId}/activitytypes/${activityType.activityTypeId}/documenttypes/${activityTypeDocumentType.documentTypeId}`
        )
        commit("REMOVE_ACTIVITY_TYPE_DOCUMENT_TYPE", { index, documentTypeIndex })
        return Promise.resolve(activityTypeDocumentType)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },
    addActivityTypeSubActivityType: async (
      { commit },
      { activityType, subActivityType, index }
    ) => {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.post(
          `/rest/v2/${activityType.serviceId}/activitytypes/${activityType.activityTypeId}/subactivitytype`,
          subActivityType
        )
        commit("ADD_ACTIVITY_TYPE_SUB_ACTIVITY_TYPE", { subActivityType: res.data, index })
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },
    deleteActivityTypeSubActivityType: async (
      { commit },
      { activityType, subActivityType, index, subActivityTypeIndex }
    ) => {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.delete(
          `/rest/v2/${activityType.serviceId}/activitytypes/${activityType.activityTypeId}/status/${subActivityType.parentActivityTypeStatus}/subactivitytype/${subActivityType.subActivityTypeId}`
        )
        commit("REMOVE_ACTIVITY_TYPE_SUB_ACTIVITY_TYPE", { index, subActivityTypeIndex })
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    }
  }
}
