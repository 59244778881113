import Vue from "vue"

export default {
  namespaced: true,
  state: {
    loading: false,
    userServices: [],
    userScripts: [],
    searchUsers: []
  },
  getters: {
    userServiceGroups: state => serviceId =>
      (state.userServices.find(service => service.serviceId == serviceId) || {}).groups || [],
    userService: state => serviceId =>
      state.userServices.find(service => service.serviceId === serviceId) || {},
    filteredUserServices: state => state.userServices.filter(service => !service.notUseAfter)
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_USER_SERVICES(state, values) {
      state.userServices = values
    },
    SET_USER_SCRIPTS(state, values) {
      state.userScripts = values
    },
    SET_SEARCH_USERS(state, searchUsers) {
      state.searchUsers = [].concat(searchUsers).map(user => {
        return {
          id: user.userId,
          value: `${user.surnames}, ${user.name}`
        }
      })
    }
  },
  actions: {
    async getUserServices({ commit }) {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.get(`/api-desk/rest/users/services`)
        commit("SET_USER_SERVICES", res.data)
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },
    async getUserScripts({ commit }, { serviceId, channels = "email,call,backoffice" }) {
      try {
        commit("SET_LOADING", true)
        //const res = await Vue.axios.get(`/rest/users/services`)
        const params = {
          serviceId,
          channels
        }
        const res = await Vue.axios.get("/rest/scripts", { params })
        commit("SET_USER_SCRIPTS", res.data)
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },
    async searchUser({ commit }, { searchValue, workplaceId, serviceId, groupId, rankId }) {
      try {
        workplaceId = workplaceId || ""
        serviceId = serviceId || ""
        groupId = groupId || ""
        rankId = rankId || ""
        commit("SET_LOADING", true)
        const queryParams = `search=${searchValue}&workplaceId=${workplaceId}&serviceId=${serviceId}&groupId=${groupId}&rankId=${rankId}`
        const res = await Vue.axios.get(
          `/api-desk/rest/users?isstaff=true&active=true&${queryParams}`
        )
        commit("SET_SEARCH_USERS", res.data)
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },
    resetSearchUser({ commit }) {
      commit("SET_SEARCH_USERS", [])
    },
    async searchUserById({ commit }, userId) {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.get(`/api-desk/rest/users/${userId}`)
        commit("SET_SEARCH_USERS", res.data)
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    }
  }
}
