import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import "./plugins/axios"
import moment from "moment"
import momentDurationFormat from "moment-duration-format"
import "moment/locale/es"

momentDurationFormat(moment)

moment.locale("es")
moment.updateLocale("es", {
  weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"],
  months: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
  ]
})
moment.relativeTimeRounding(Math.floor)

moment.relativeTimeThreshold("s", 60)
moment.relativeTimeThreshold("m", 60)
moment.relativeTimeThreshold("h", 24)
moment.relativeTimeThreshold("d", 31)
//moment.relativeTimeThreshold("w", 4)
moment.relativeTimeThreshold("M", 12)
Vue.prototype.moment = moment

Vue.config.productionTip = false

store.$app = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app")
