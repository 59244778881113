<template>
  <v-app>
    <v-app-bar
      v-if="isAuth && showBar"
      app
      clipped-left
      color="blue-grey lighten-5"
      light
      height="65"
    >
      <v-tabs icons-and-text show-arrows class="mini-tabs">
        <v-tabs-slider></v-tabs-slider>
        <v-tab to="/myhome">
          Inicio
          <v-icon>mdi-home</v-icon>
        </v-tab>
        <v-tab to="/mail">
          Correo
          <v-badge
            :value="unassignedMailCount > 0"
            :content="unassignedMailCount ? unassignedMailCount.toString() : 0"
            color="orange"
            light
            overlap
          >
            <v-icon>mdi-email</v-icon>
          </v-badge>
        </v-tab>
        <v-tab to="/cti">
          CTI
          <v-icon>mdi-phone</v-icon>
        </v-tab>
        <v-tab to="/chat">
          Chat
          <v-icon>mdi-chat</v-icon>
        </v-tab>
        <v-tab v-if="allowSupervision" to="/monitor">
          Monitor
          <v-icon>mdi-monitor-dashboard</v-icon>
        </v-tab>
        <!--v-tab to="/task">
          Tareas
          <v-icon>mdi-calendar-check</v-icon>
        </v-tab-->
        <v-tab v-if="allowAdmin" to="/admin">
          Admin
          <v-icon>mdi-application-settings</v-icon>
        </v-tab>
        <v-tab v-if="allowDevel" to="/devel">
          Dev
          <v-icon>mdi-dev-to</v-icon>
        </v-tab>
      </v-tabs>
      <v-spacer></v-spacer>
      <span class="caption">{{ user.name }} {{ user.surnames }} ({{ user.userId }})</span>
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon v-if="allowDevel">mdi-emoticon-devil</v-icon>
            <v-icon v-else-if="allowAdmin">mdi-account-star</v-icon>
            <v-icon v-else-if="allowSupervision">mdi-account-plus</v-icon>
            <v-icon v-else>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout"
            ><v-list-item-title>Cerrar sesión</v-list-item-title></v-list-item
          >
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main id="app-content-crm" class="fondo">
      <router-view />
    </v-main>
    <!--v-main v-else>
      <v-container>
        <v-alert class="mt-10" color="blue-grey darken-3" dark icon="mdi-alert">
          No se ha especificado un token o el token no es válido: {{ errorMessage }}
        </v-alert>
      </v-container>
    </v-main-->

    <!--v-footer v-if="isAuth" app>
      <v-spacer></v-spacer>
      app footer...
      <v-spacer></v-spacer>
    </v-footer-->

    <dialogs ref="dialogs" />
  </v-app>
</template>

<script>
  import Dialogs from "@/components/common/Dialogs"
  import { mapState, mapGetters, mapActions } from "vuex"

  export default {
    name: "App",
    data: () => ({
      unassignedTimer: null
    }),
    watch: {
      isAuth(value) {
        value && this.init()
      }
    },
    computed: {
      ...mapState("config", ["showBar"]),
      ...mapState("mail", ["unassignedMailCount"]),
      ...mapState("auth", ["isAuth", "errorMessage", "decodedToken", "user", "token"]),
      ...mapGetters("auth", ["hasRole", "hasAnyRole"]),
      allowSupervision() {
        return this.hasAnyRole("ITDEVEL", "CRMADM", "CRMMAILSUP", "CRMCTISUP")
      },
      allowAdmin() {
        return this.hasAnyRole("ITDEVEL", "CRMADM", "CRMMAILSUP")
      },
      allowDevel() {
        return this.hasRole("ITDEVEL")
      }
    },
    methods: {
      ...mapActions("auth", ["getLoginUser", "resetError", "resetAuth"]),
      ...mapActions("config", ["setShowBar", "setAppContentHeight", "setShowMenu"]),
      ...mapActions("user", ["getUserServices"]),
      ...mapActions("mail", ["getUnassignedMailCount"]),
      handleResize() {
        //const height = document.getElementById("app-content-crm").firstChild.clientHeight
        const height = window.innerHeight - (this.showBar && 65) || 0
        this.setAppContentHeight(height)
      },
      init() {
        this.getUserServices().catch(_error => {})
        this.getUnassignedMailCount()
        /*this.unassignedTimer = setInterval(() => {
          if (this.isAuth) {
            this.getUnassignedMailCount()
          } else {
            if (this.unassignedTimer) {
              clearInterval(this.unassignedTimer)
            }
          }
        }, 1000 * 60)*/
      },
      logout() {
        this.resetAuth()
      }
    },
    mounted() {
      const params = new URLSearchParams(window.location.search)
      if (params.has("nobar")) {
        this.setShowBar(false)
        this.setShowMenu(false)
      }
      this.$root.$dialogs = this.$refs.dialogs
      this.resetError()
      setTimeout(() => {
        this.handleResize()
        window.addEventListener("resize", this.handleResize)
      }, 125)

      const token = params.get("token")
      if (this.isAuth && (token === null || token === this.token)) {
        this.init()
      } else {
        if (token != null) {
          this.getLoginUser(token)
            .then(() => {
              this.init()
            })
            .catch(_error => {})
        }
      }
    },
    beforeDestroy() {
      //window.removeEventListener("resize", this.handleResize)
    },
    components: {
      Dialogs
    }
  }
</script>

<style type="text/css">
  html {
    overflow-y: hidden !important;
  }
  .v-btn:focus {
    outline: none;
  }
  .fondo {
    background-image: linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)),
      url("~@/assets/callcenter.jpg");
    background-size: cover;
    width: 100%;
    height: 100%;
    /*opacity: 0.5;*/
  }
  .v-tabs.mini-tabs {
    flex: 1 1 auto;
    width: 75%;
  }
</style>
