export default {
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_UPDATING(state, value) {
    state.updating = value
  },
  SET_MAIL_BOXES(state, value) {
    state.mailBoxes = value
  },
  ADD_MAIL_BOX(state, value) {
    state.mailBoxes.push(value)
  },
  UPDATE_MAIL_BOX(state, { mailBox, index }) {
    Object.assign(state.mailBoxes[index], mailBox)
  },
  REMOVE_MAIL_BOX(state, index) {
    state.mailBoxes.splice(index, 1)
  },
  ADD_ACTIVITY_TYPE(state, { mailBoxIndex, createdActivityType }) {
    state.mailBoxes[mailBoxIndex].activityTypes.push(createdActivityType)
  },
  DELETE_ACTIVITY_TYPE(state, { mailBoxIndex, index }) {
    state.mailBoxes[mailBoxIndex].activityTypes.splice(index, 1)
  },
  ADD_MAIL_BOX_SCRIPT(state, { mailBoxIndex, mailBoxScript }) {
    state.mailBoxes[mailBoxIndex].scripts.push(mailBoxScript)
  },
  DELETE_MAIL_BOX_SCRIPT(state, { mailBoxIndex, index }) {
    state.mailBoxes[mailBoxIndex].scripts.splice(index, 1)
  },
  ADD_MAILBOX_ADDRESS_BOOK(state, { addressBook, index }) {
    state.mailBoxes[index].addressBooks.push(addressBook)
  },
  DELETE_MAILBOX_ADDRESS_BOOK(state, { index, addressBookIndex }) {
    state.mailBoxes[index].addressBooks.splice(addressBookIndex, 1)
  },
  UPDATE_ACTIVITY_TYPE(state, { mailBoxIndex, activityType, index }) {
    Object.assign(state.mailBoxes[mailBoxIndex].activityTypes[index], activityType)
  }
}
