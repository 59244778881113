import Vue from "vue"

export default {
  namespaced: true,
  state: {
    loading: false,
    unusableContactMethods: [],
    contactMethodTypes: []
  },
  getters: {
    contactMethodType: state => contactType =>
      state.contactMethodTypes.find(cmt => cmt.contactType === contactType) || {}
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_CONTACT_METHOD_TYPES(state, value) {
      state.contactMethodTypes = value
    },
    SET_UNUSABLE_CONTACT_METHODS(state, unusableContactMethods) {
      state.unusableContactMethods = unusableContactMethods
    },
    ADD_UNUSABLE_CONTACT_METHOD(state, unusableContactMethod) {
      state.unusableContactMethods.push(unusableContactMethod)
    },
    REMOVE_UNUSABLE_CONTACT_METHOD(state, unusableContactMethod) {
      const index = state.unusableContactMethods.findIndex(
        ucm => ucm.notUsableContactMethodId === unusableContactMethod.notUsableContactMethodId
      )
      state.unusableContactMethods.splice(index, 1)
    }
  },
  actions: {
    async getContactMethodTypes({ commit }) {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.get("/rest/activities/contact-method-types")
        commit("SET_CONTACT_METHOD_TYPES", res.data)
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },

    async getUnusableContactMethods({ commit }, serviceId) {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.get(`/rest/v2/${serviceId}/activities/contact-methods/unused`)
        commit("SET_UNUSABLE_CONTACT_METHODS", res.data)
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },

    async addUnusableContactMethod({ commit }, { serviceId, unusableContactMethod }) {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.post(
          `/rest/v2/${serviceId}/activities/contact-methods/unused`,
          unusableContactMethod
        )
        commit("ADD_UNUSABLE_CONTACT_METHOD", res.data)
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },

    async deleteUnusableContactMethod({ commit }, { serviceId, unusableContactMethod }) {
      try {
        commit("SET_LOADING", true)
        const res = await Vue.axios.delete(
          `/rest/v2/${serviceId}/activities/contact-methods/unused/${unusableContactMethod.notUsableContactMethodId}`
        )
        commit("REMOVE_UNUSABLE_CONTACT_METHOD", unusableContactMethod)
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    }
  }
}
