import { Buffer } from "buffer"
import router from "@/router/index.js"
import Vue from "vue"

export default {
  namespaced: true,
  state: {
    isAuth: false,
    token: null,
    decodedToken: null,
    user: null,
    error: false,
    errorMessage: null,
    logging: false
  },
  getters: {
    hasRole: state => roleId => (state.user ? state.user.roles : []).includes(roleId),
    hasAnyRole:
      state =>
      (...roles) =>
        (state.user ? state.user.roles : []).some(r => roles.includes(r))
  },
  mutations: {
    RESET_AUTH(state, message) {
      state.isAuth = false
      state.token = null
      state.decodedToken = null
      state.user = null
      if (message) {
        state.error = true
        state.errorMessage = message
      } else {
        state.error = false
        state.errorMessage = null
      }
      state.logging = false
    },
    SET_TOKEN(state, value) {
      try {
        try {
          const base64 = Buffer.from(value.split(".")[1], "base64")
          state.decodedToken = JSON.parse(base64)
          state.token = value
        } catch (error) {
          throw "Token no válido"
        }
        if (new Date() >= new Date(new Date(0).setUTCSeconds(state.decodedToken.exp))) {
          throw "Token expirado"
        }
        state.isAuth = true
      } catch (error) {
        state.isAuth = false
        state.token = null
        state.decodedToken = null
        state.error = true
        state.errorMessage = error
        throw error
      }
    },
    SET_USER(state, value) {
      state.user = value
    },
    CLEAR_ERROR(state) {
      state.error = false
      state.errorMessage = null
    },
    SET_ERROR(state, error) {
      state.error = true
      state.errorMessage = error.data ? error.data.message : error
    },
    SET_LOGGING(state, value) {
      state.logging = value
    },
    SET_USER_AVATAR(state, userAvatar) {
      state.userAvatar = userAvatar
    }
  },
  actions: {
    resetError({ commit }) {
      commit("CLEAR_ERROR")
    },
    /*setToken({ commit }, value) {
      try {
        commit("SET_TOKEN", value)
        return Promise.resolve(value)
      } catch (error) {
        return Promise.reject(error)
      }
    },*/
    async getLoginUser({ commit }, token) {
      try {
        commit("CLEAR_ERROR", null)
        commit("SET_LOGGING", true)
        const params = { token }
        const res = await Vue.axios.get("/api-desk/rest/auth/login", { params })
        commit("SET_TOKEN", res.data.token)
        commit("SET_USER", res.data.user)
        return Promise.resolve(res.data)
      } catch (error) {
        commit("SET_ERROR", error.response || error)
        return Promise.reject(error)
      } finally {
        commit("SET_LOGGING", false)
      }
    },
    async loginUser({ commit }, payload) {
      try {
        commit("CLEAR_ERROR", null)
        commit("SET_LOGGING", true)
        const res = await Vue.axios.post("/api-desk/rest/auth/login", payload)
        commit("SET_TOKEN", res.data.token)
        commit("SET_USER", res.data.user)
        const pathname = router.app.$route.query.pathname
        if (pathname) {
          router.push({ path: pathname })
        } else {
          router.push({ name: "Home" })
        }
      } catch (error) {
        commit("SET_ERROR", error.response || error)
      } finally {
        commit("SET_LOGGING", false)
      }
    },
    resetAuth({ commit }, message) {
      commit("RESET_AUTH", message)
      if (router.currentRoute.name !== "Login") {
        router.push({ name: "Login" })
      }
    },
    async getUserAvatar({ commit }, avatarHash) {
      try {
        const params = {}
        params.hash = avatarHash
        const res = await Vue.axios({
          method: "GET",
          url: "/api-desk/rest/users/avatar",
          responseType: "blob",
          headers: {
            "Content-Type": "image/png"
          },
          params
        })
        const url = URL.createObjectURL(res.data)
        commit("SET_USER_AVATAR", url)

        return Promise.resolve(url)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        // commit("SET_LOGGING", false)
      }
    },
    setUserAvatar({ commit }, userAvatar) {
      commit("SET_USER_AVATAR", userAvatar)
    }
  }
}
