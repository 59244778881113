import moment from "moment"
import axios from "axios"

export default {
  namespaced: true,
  state: {
    loading: false,
    timeZones: [],
    userTimeZones: [],
    dateFormatOptions: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    },
    datetime: moment,
    defaultDateFormat: "DD/MM/YYYY",
    defaultIsoDateTimeFormat: "YYYY-MM-DDTHH:mm:ss",
    defaultTimeFormat: "HH:mm",
    defaultTimeZone: "Europe/Madrid",
    dateInputValidFormats: [
      "YYYY-MM-DDTHH:mm:ss.SSSZ",
      "YYYY-MM-DDTHH:mm:ss.SSSSSS",
      "YYYY-MM-DDTHH:mm:ss.SSS",
      "YYYY-MM-DDTHH:mm:ss",
      "YYYY-MM-DD",
      "YYYY-MM"
    ],
    timeInputValidFormats: [
      "YYYY-MM-DDTHH:mm:ss.SSSZ",
      "YYYY-MM-DDTHH:mm:ss.SSSSSS",
      "YYYY-MM-DDTHH:mm:ss.SSS",
      "YYYY-MM-DDTHH:mm:ss",
      "HH:mm:ss",
      "HH:mm"
    ]
  },
  getters: {
    toIso: state => date => {
      const d = state.datetime(date, state.dateInputValidFormats, true)
      return date && d.isValid() ? d.format(state.defaultIsoDateTimeFormat) : null
    },
    dateFormat:
      state =>
      ({ date, format = null, inputFormat = null, strict = false }) => {
        const d = state.datetime(date, inputFormat || state.dateInputValidFormats, strict)
        return date && d.isValid() ? d.format(format || state.defaultDateFormat) : null
      },
    timeFormat:
      state =>
      ({ date, format = null, inputFormat = null, strict = false }) => {
        const d = state.datetime(date, inputFormat || state.timeInputValidFormats, strict)
        return date && d.isValid() ? d.format(format || state.defaultTimeFormat) : null
      },
    timeFormatToText: state => dateTime =>
      moment(dateTime).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY")
        ? moment(dateTime).format("HH:mm") + "h"
        : moment(dateTime).week() === moment().week()
        ? moment(dateTime).format("dd. HH:mm") + "h"
        : moment(dateTime).year() === moment().year()
        ? moment(dateTime).format("D MMM HH:mm") + "h"
        : moment(dateTime).format("D MMM YY, HH:mm") + "h",
    localDate:
      state =>
      ({ date, timeZone = null }) => {
        timeZone = timeZone || state.defaultTimeZone
        return state
          .datetime(
            new Date(date).toLocaleString(
              "es-ES",
              Object.assign({ timeZone }, state.dateFormatOptions)
            ),
            "DD/MM/YYYY HH:mm:ss"
          )
          .format("YYYY-MM-DDTHH:mm:SS.SSSZ")
      },
    timeDurationToText: state => diff => {
      const duration = state.datetime.duration(diff)
      const years = state.datetime.duration({ years: duration.years() })
      const months = state.datetime.duration({ months: duration.months() })
      const days = state.datetime.duration({ days: duration.days() })
      const hours = state.datetime.duration({ hours: duration.hours() })
      const minutes = state.datetime.duration({ minutes: duration.minutes() })
      const seconds = state.datetime.duration({ seconds: duration.seconds() })
      const result = []
      if (years.years() > 0) {
        result.push(years.humanize())
      }
      if (months.months() > 0) {
        result.push(months.humanize({ d: 30 }))
      }
      if (days.days() > 0) {
        result.push(days.humanize())
      }
      if (hours.hours() > 0) {
        result.push(hours.humanize())
      }
      if (minutes.minutes() > 0) {
        result.push(minutes.humanize())
      }
      if (seconds.seconds() > 0) {
        result.push(seconds.humanize())
      }
      if (result.length == 0) {
        return null
      }
      return result.reduce((prior, current, index, list) => {
        return prior + (index == list.length - 1 ? " y " : ", ") + current
      })
    },
    timeDurationArray: (state, getters) => array => {
      const diff = array.reduce((diff, current) => {
        return (diff += state.datetime(current.end).diff(current.begin))
      }, 0)
      return getters.timeDurationToText(diff)
    },
    timeDuration: (state, getters) => (begin, end) => {
      const diff = state.datetime(end).diff(begin)
      return getters.timeDurationToText(diff)
    },
    timeDurationCompact: (state, getters) => (begin, end) => {
      const diff = state.datetime(end).diff(begin)
      const duration = state.datetime.duration(diff)
      return (
        duration.hours().toString().padStart(2, "0") +
        ":" +
        duration.minutes().toString().padStart(2, "0") +
        ":" +
        duration.seconds().toString().padStart(2, "0")
      )
    }
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_TIMEZONES(state, timeZones) {
      state.timeZones = timeZones
    },
    SET_USER_TIMEZONES(state, timeZones) {
      state.userTimeZones = timeZones
    }
  },
  actions: {
    async getTimeZones({ commit }) {
      try {
        commit("SET_LOADING", true)
        commit("SET_TIMEZONES", [])
        const res = await axios({
          method: "GET",
          url: `/api-desk/rest/workplaces/timezones`
        })
        commit("SET_TIMEZONES", res.data)
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    },
    async getUserTimeZones({ commit }) {
      try {
        commit("SET_LOADING", true)
        commit("SET_USER_TIMEZONES", [])
        const res = await axios({
          method: "GET",
          url: `/api-desk/rest/workplaces/user/timezones`
        })
        commit("SET_USER_TIMEZONES", res.data)
        return Promise.resolve(res.data)
      } catch (error) {
        return Promise.reject(error.response || error)
      } finally {
        commit("SET_LOADING", false)
      }
    }
  }
}
