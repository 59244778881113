export default {
  loading: false,
  updating: false,
  emailAccounts: [],
  polledAccounts: {
    running: false,
    totalAccounts: 0
  },
  pollingMonitors: [
    {
      env: "dev",
      name: "Desarrollo",
      host: "https://des-api.globaliacc.com",
      action: "/rest/v2/emailpolling/crm-accounts-loop"
    },
    {
      env: "staging",
      name: "Preproducción",
      host: "https://pre-api.globaliacc.com",
      action: "/rest/v2/emailpolling/crm-accounts-loop"
    },
    {
      env: "production",
      name: "Producción GCC01",
      host: "https://api.globaliacc.com",
      action: "/rest/v2/emailpolling/crm-accounts-loop/GCC01"
    },
    {
      env: "production",
      name: "Producción GCC02",
      host: "https://api.globaliacc.com",
      action: "/rest/v2/emailpolling/crm-accounts-loop/GCC02"
    }
  ],
  readProtocols: ["imaps", "pop3s"],
  readSSLTypes: [
    {
      id: "SSL",
      text: "SSL"
    },
    {
      id: "XOAUTH2",
      text: "Office 365 Oauth2"
    }
  ],
  sendProtocols: ["smtp"],
  sendSSLTypes: [
    {
      id: "STARTTLS",
      text: "STARTTLS"
    },
    {
      id: "XOAUTH2",
      text: "Office 365 Oauth2"
    },
    {
      id: "SSL",
      text: "SSL"
    }
  ],
  persistMethods: ["API-DOCS"]
}
