export default {
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_ACTIVITY(state, value) {
    state.activity = value
  },
  SET_ACTIVITIES(state, values) {
    state.activities = values
  }
}
