import moment from "moment"

export default {
  namespaced: true,
  state: {
    showBar: true,
    appContentHeight: null,
    showMenu: true
  },
  getters: {
    dateToText: _state => value => {
      const isToday = moment(value).startOf("day").isSame(moment().startOf("day"))
      return isToday ? moment(value).format("HH:mm") : moment(value).format("D MMM")
    },
    dateTimeToText: _state => value => {
      const isToday = moment(value).startOf("day").isSame(moment().startOf("day"))
      return isToday ? moment(value).format("HH:mm") : moment(value).format("D MMM HH:mm")
    }
  },
  mutations: {
    SET_SHOW_BAR(state, value) {
      state.showBar = value
    },
    SET_APP_CONTENT_HEIGHT(state, value) {
      state.appContentHeight = value
    },
    SET_SHOW_MENU(state, value) {
      state.showMenu = value
    }
  },
  actions: {
    setShowBar({ commit }, value) {
      commit("SET_SHOW_BAR", value)
    },
    setShowMenu({ commit }, value) {
      commit("SET_SHOW_MENU", value)
    },
    setAppContentHeight({ commit }, value) {
      commit("SET_APP_CONTENT_HEIGHT", value)
    }
  }
}
