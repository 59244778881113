import Vue from "vue"

const clearTemplates = ({ commit }) => {
  commit("SET_TEMPLATES", [])
}

const addTemplate = async ({ commit }, template) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.post("/rest/v2/templates", template)
    commit("ADD_TEMPLATE", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const updateTemplate = async ({ commit }, { template, index }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.put(`/rest/v2/templates/${template.templateName}`, template)

    commit("UPDATE_TEMPLATE", { template: res.data, index })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const deleteTemplate = async ({ commit }, { template, index }) => {
  try {
    commit("SET_UPDATING", true)
    await Vue.axios.delete(`/rest/v2/templates/${template.templateName}`)
    commit("REMOVE_TEMPLATE", index)
    return Promise.resolve(template)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const getTemplates = async ({ commit }, { serviceId, groupId, applicationId }) => {
  try {
    commit("SET_LOADING", true)
    const params = { serviceId, groupId, applicationId }
    const res = await Vue.axios.get("/rest/v2/templates", { params })
    commit("SET_TEMPLATES", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const addTemplateLanguage = async ({ commit }, { language, templateName, index }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.post(
      `/rest/v2/templates/${templateName}/language/${language.languageId}`,
      language
    )
    commit("ADD_TEMPLATE_LANGUAGE", { language: res.data, index })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const updateTemplateLanguage = async ({ commit }, { language, index, langIndex, templateName }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.put(
      `/rest/v2/templates/${templateName}/language/${language.languageId}`,
      language
    )
    commit("UPDATE_TEMPLATE_LANGUAGE", { language: res.data, index, langIndex })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const deleteTemplateLang = async ({ commit }, { templateName, index, langIndex, language }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.delete(
      `/rest/v2/templates/${templateName}/language/${language.languageId}`
    )
    commit("DELETE_TEMPLATE_LANGUAGE", { language: res.data, index, langIndex, templateName })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const clearTemplateApplications = ({ commit }) => {
  commit("SET_TEMPLATE_APPLICATIONS", [])
}

const getTemplateApplications = async ({ commit }, { serviceId, groupId = null }) => {
  try {
    const params = { serviceId, groupId }
    commit("SET_LOADING", true)
    const res = await Vue.axios.get("/rest/v2/templates/applications", { params })
    commit("SET_TEMPLATE_APPLICATIONS", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const getAllTemplateApplications = async ({ commit }) => {
  try {
    // commit("SET_LOADING", true)
    const res = await Vue.axios.get("/rest/v2/templates/applications")
    commit("SET_ALL_TEMPLATE_APPLICATIONS", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    // commit("SET_LOADING", false)
  }
}

const compileTemplate = async ({ commit }, { templateName, languageId, dataExample }) => {
  try {
    commit("SET_LOADING", true)
    const config = {
      headers: {
        Accept: "text/html",
        "Content-Type": "application/json"
      }
    }
    const res = await Vue.axios.post(
      `/rest/v2/templates/merge/${templateName}/${languageId}`,
      dataExample ? dataExample : {},
      config
    )
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

export default {
  clearTemplates,
  getTemplates,
  clearTemplateApplications,
  getTemplateApplications,
  getAllTemplateApplications,
  addTemplate,
  updateTemplate,
  deleteTemplate,
  addTemplateLanguage,
  updateTemplateLanguage,
  deleteTemplateLang,
  compileTemplate
}
