export default {
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_MONITOR_MAILBOXES(state, values) {
    state.monitorMailBoxes = values
  },
  UPDATE_LAST_REFRESH(state) {
    state.lastRefresh = new Date()
  }
}
