import Vue from "vue"

const getActivityById = async ({ commit }, { serviceId, activityId }) => {
  try {
    commit("SET_LOADING", true)
    const res = await Vue.axios.get(`/rest/activities/${serviceId}/activity/${activityId}`)
    commit("SET_ACTIVITY", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

export default {
  getActivityById
}
