import Vue from "vue"
import Vuetify from "vuetify/lib"
import es from "vuetify/lib/locale/es"
import "@mdi/font/css/materialdesignicons.css"

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { es },
    current: "es"
  },
  icons: {
    iconfont: "mdi"
  }
})
