import moment from "moment"

export default {
  namespaced: true,
  state: {},
  getters: {
    timeFormat: state => dateTime =>
      moment(dateTime).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY")
        ? moment(dateTime).format("HH:mm") + "h"
        : moment(dateTime).week() === moment().week()
        ? moment(dateTime).format("dd. HH:mm") + "h"
        : moment(dateTime).year() === moment().year()
        ? moment(dateTime).format("D MMM HH:mm") + "h"
        : moment(dateTime).format("D MMM YY, HH:mm") + "h"
  },
  mutations: {},
  actions: {}
}
