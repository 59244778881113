import Vue from "vue"
import htmlSanitize from "@/plugins/htmlSanitize.js"

const getMyMailBoxes = async ({ commit }, serviceId) => {
  try {
    commit("SET_LOADING", true)
    const res = await Vue.axios.get(`/rest/mailboxes/${serviceId}`)
    commit("SET_MAIL_BOXES", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const getMails = async (
  { commit },
  {
    serviceId,
    boxType,
    mailBox,
    assignType,
    dateFrom,
    dateTo,
    multisearch,
    statusTypes,
    sortType,
    pagination
  }
) => {
  try {
    commit("SET_LOADING", true)
    commit("SET_MAILS", { total: 0, items: [] })
    const params = {}
    if (mailBox != null) {
      params.accounts = mailBox.inboundAccountId
    }
    params.dateFrom = dateFrom
    params.dateTo = dateTo
    params.multisearch = multisearch
    params.statusTypes = statusTypes
    params.sortType = sortType
    params.page = pagination.page
    params.itemsPerPage = pagination.itemsPerPage
    const res = await Vue.axios.get(`/rest/emails/${serviceId}/${boxType}/${assignType}`, {
      params
    })
    commit("SET_MAILS", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const getDrafts = async ({ commit }, { serviceId, mailBox = null }) => {
  try {
    const params = {}
    if (mailBox != null) {
      params.accounts = mailBox.inboundAccountId
    }
    commit("SET_LOADING", true)
    const res = await Vue.axios.get(`/rest/activities/${serviceId}/activity/drafts/user`, {
      params
    })
    commit("SET_DRAFTS", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const clearMails = ({ commit }) => {
  commit("SET_MAILS", [])
}

const getUnassignedMailCount = async ({ commit }) => {
  try {
    const res = await Vue.axios.get("/rest/emails/inbound/unassigned/count")
    commit("SET_UNASSIGNED_MAIL_COUNT", res.data.count)
    if (res.status == 200) {
      commit("SET_UNASSIGNED_MAIL_COUNT", res.data.count)
    } else {
      commit("SET_UNASSIGNED_MAIL_COUNT", 0)
    }
  } catch (error) {
    commit("SET_UNASSIGNED_MAIL_COUNT", 0)
  } finally {
    /*if (this.mailsTimerId != null) {
      clearTimeout(this.mailsTimerId)
    }
    const timerId = setTimeout(() => {
      this.getUnassignedMailCount()
    }, this.mailsTime)
    this.setMailsTimerId(timerId)*/
  }
}

/*const assignInboundMail = async ({ commit }, { serviceId, emailId, activityType }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.post(
      `/rest/emails/${serviceId}/inbound/${emailId}/assign`,
      activityType
    )
    commit("ASSIGN_INBOUND_MAIL", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}*/

/*const unassignInboundMail = async ({ commit }, { serviceId, emailId }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.delete(
      `/rest/emails/${serviceId}/inbound/${emailId}/assign`
    )
    commit("UNASSIGN_INBOUND_MAIL")
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}*/

/*const updateInboundMail = async ({ commit }, { serviceId, emailId, inboundEmailUpdates }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.put(
      `/rest/emails/${serviceId}/inbound/${emailId}/update`,
      inboundEmailUpdates
    )
    commit("UPDATE_INBOUND_MAIL", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}*/

const deleteInboundMail = async ({ commit }, { serviceId, emailId, index }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.delete(`/rest/emails/${serviceId}/inbound/${emailId}`)
    commit("DELETE_INBOUND_MAIL", index)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const updateActivity = async (
  { commit },
  { serviceId, activity, index, boxType, supervised = false }
) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.put(
      `/rest/activities/${serviceId}/activity/${activity.activityId}${
        supervised ? "/supervised" : ""
      }`,
      activity
    )
    commit("UPDATE_ACTIVITY", { activity: res.data, index, boxType })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

/*const updateActivityStatus = async (
  { commit },
  { serviceId, activityId, status, index, boxType }
) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.put(
      `/rest/activities/${serviceId}/activity/${activityId}/status/${status}/user`
    )
    commit("UPDATE_ACTIVITY_STATUS", { activity: res.data, index, boxType })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}*/

const clearMailContent = ({ commit }) => {
  commit("SET_MAIL_CONTENT", null)
}

const setMailContent = ({ commit }, content) => {
  commit("SET_MAIL_CONTENT", content)
}

const getMailContent = async ({ commit }, { mail, direction }) => {
  try {
    commit("SET_LOADING_CONTENT", true)
    commit("SET_MAIL_CONTENT", null)
    const res = await Vue.axios.get(
      `/rest/emails/${direction}/${mail.emailAccountId}/${mail.emailId}/content`,
      { timeout: 60 * 1000 }
    )
    const sanitized = htmlSanitize(res.data)
    commit("SET_MAIL_CONTENT", sanitized)
    return Promise.resolve(res.data)
  } catch (error) {
    commit("SET_MAIL_CONTENT_ERROR", error.response || error)
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING_CONTENT", false)
  }
}

const getMailReply = async ({ commit }, { mail, direction, replyType }) => {
  try {
    commit("SET_LOADING_CONTENT", true)
    const res = await Vue.axios.get(
      `/rest/emails/${direction}/${mail.emailAccountId}/${mail.emailId}/${replyType}`
    )
    const sanitized = htmlSanitize(res.data)
    return Promise.resolve(sanitized)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING_CONTENT", false)
  }
}

/*const sendMail = async ({ commit }, { serviceId, relatedEmailId, emailAccountId, email }) => {
  try {
    commit("SET_LOADING_CONTENT", true)
    const res = await Vue.axios.post(
      `/rest/emails/${serviceId}/outbound/${emailAccountId}/send${
        relatedEmailId != null ? "/" + relatedEmailId : ""
      }`,
      email
    )
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING_CONTENT", false)
  }
}*/

const sendActivityMail = async (
  { commit },
  { emailAccountId, activityId, relatedEmailId, email }
) => {
  try {
    commit("SET_LOADING_CONTENT", true)
    const res = await Vue.axios.post(
      `/rest/emails/${emailAccountId}/activity/${activityId}/outbound${
        relatedEmailId != null ? "/" + relatedEmailId : ""
      }`,
      email
    )
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING_CONTENT", false)
  }
}

const clearMail = ({ commit }) => {
  commit("SET_MAIL", null)
}

const setMail = ({ commit }, mail) => {
  commit("SET_MAIL", mail)
}

const updateMail = ({ commit }, mail) => {
  commit("UPDATE_MAIL", mail)
}

const getActivityDrafts = async ({ commit }, { serviceId, activityId }) => {
  try {
    commit("SET_LOADING_CONTENT", true)
    const res = await Vue.axios.get(`/rest/activities/${serviceId}/activity/${activityId}/drafts`)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING_CONTENT", false)
  }
}

const setActivityForDraft = async ({ commit }, { activity, emailAccountId }) => {
  commit("SET_MAIL_ACTIVITY_FOR_DRAFT", { activity, emailAccountId })
}

const createActivity = async ({ commit }, { serviceId, activity, emailAccountId }) => {
  try {
    commit("SET_LOADING_CONTENT", true)
    const res = await Vue.axios.post(`/rest/activities/${serviceId}`, activity)
    commit("SET_MAIL_ACTIVITY_FOR_DRAFT", { activity: res.data, emailAccountId })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING_CONTENT", false)
  }
}

/*const createActivityForDraft = async (
  { commit },
  { serviceId, activityTypeId, activityDraft, emailAccountId }
) => {
  try {
    commit("SET_LOADING_CONTENT", true)
    const res = await Vue.axios.post(
      `/rest/activities/${serviceId}/activitytypes/${activityTypeId}/activity/draft`,
      activityDraft
    )
    commit("SET_MAIL_ACTIVITY_FOR_DRAFT", { activity: res.data, emailAccountId })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING_CONTENT", false)
  }
}*/

const addActivityDraft = async (
  { commit },
  { serviceId, activityId, activityDraft, index, boxType }
) => {
  try {
    commit("SET_LOADING_CONTENT", true)
    const res = await Vue.axios.post(
      `/rest/activities/${serviceId}/activity/${activityId}/drafts`,
      activityDraft
    )
    commit("UPDATE_DRAFT", { index, value: true, boxType, draft: res.data })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING_CONTENT", false)
  }
}

const deleteActivityDraft = async ({ commit }, { serviceId, activityId, index, boxType }) => {
  try {
    commit("SET_LOADING_CONTENT", true)
    const res = await Vue.axios.delete(
      `/rest/activities/${serviceId}/activity/${activityId}/drafts`
    )
    commit("UPDATE_DRAFT", { index, value: false, boxType })
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING_CONTENT", false)
  }
}

const getEmailAccounts = async ({ commit }, serviceId) => {
  try {
    commit("SET_LOADING", true)
    const params = { serviceId }
    const res = await Vue.axios.get("/rest/v2/emailaccounts", { params })
    commit("SET_EMAIL_ACCOUNTS", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

export default {
  getMyMailBoxes,
  getMails,
  getDrafts,
  clearMails,
  getUnassignedMailCount,
  //assignInboundMail,
  //unassignInboundMail,
  //updateInboundMail,
  deleteInboundMail,
  updateActivity,
  //updateActivityStatus,
  clearMailContent,
  setMailContent,
  getMailContent,
  getMailReply,
  //sendMail,
  sendActivityMail,
  clearMail,
  setMail,
  updateMail,
  getActivityDrafts,
  addActivityDraft,
  setActivityForDraft,
  createActivity,
  //createActivityForDraft,
  deleteActivityDraft,
  getEmailAccounts
}
