import Vue from "vue"

const getMailBoxes = async ({ commit }, serviceId) => {
  try {
    commit("SET_LOADING", true)
    const params = { serviceId }
    const res = await Vue.axios.get(`/rest/v2/${serviceId}/mailboxes`)
    commit("SET_MAIL_BOXES", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const addMailBox = async ({ commit }, mailBox) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.post("/rest/mailboxes", mailBox)
    commit("ADD_MAIL_BOX", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const updateMailBox = async ({ commit }, { mailBox, index }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.put(`rest/mailboxes/${mailBox.mailBoxId}`, mailBox)
    commit("UPDATE_MAIL_BOX", { mailBox: res.data, index })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const deleteMailBox = async ({ commit }, { mailBox, index }) => {
  try {
    commit("SET_UPDATING", true)
    await Vue.axios.delete(`/rest/mailboxes/${mailBox.mailBoxId}`)
    commit("REMOVE_MAIL_BOX", index)
    return Promise.resolve(mailBox)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const addActivityType = async (
  { commit },
  { mailBoxId, mailBoxIndex, activityType, serviceId }
) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.post(
      `/rest/v2/${serviceId}/mailboxes/${mailBoxId}/activity-types`,
      activityType
    )
    const createdActivityType = res.data.activityTypes.find(
      at => at.activityTypeId === activityType.activityTypeId
    )

    commit("ADD_ACTIVITY_TYPE", { mailBoxIndex, createdActivityType })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const updateActivityType = async (
  { commit },
  { mailBoxId, index, activityType, serviceId, mailBoxIndex }
) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.put(
      `/rest/v2/${serviceId}/mailboxes/${mailBoxId}/activity-types/${activityType.activityTypeId}`,
      activityType
    )
    commit("UPDATE_ACTIVITY_TYPE", { mailBoxIndex, activityType, index })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const deleteActivityType = async (
  { commit },
  { mailBoxId, activityTypeId, mailBoxIndex, index, serviceId }
) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.delete(
      `/rest/v2/${serviceId}/mailboxes/${mailBoxId}/activity-types/${activityTypeId}`
    )
    commit("DELETE_ACTIVITY_TYPE", { mailBoxIndex, index })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const addMailBoxScript = async ({ commit }, { mailBoxId, mailBoxIndex, mailBoxScript }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.post(`/rest/mailboxes/${mailBoxId}/scripts`, mailBoxScript)
    commit("ADD_MAIL_BOX_SCRIPT", { mailBoxIndex, mailBoxScript: res.data })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const deleteMailBoxScript = async ({ commit }, { mailBoxId, scriptId, mailBoxIndex, index }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.delete(`/rest/mailboxes/${mailBoxId}/scripts/${scriptId}`)
    commit("DELETE_MAIL_BOX_SCRIPT", { mailBoxIndex, index })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const addMailBoxTemplate = async ({ commit }, { mailBoxId, templateName, index }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.post(`/rest/mailboxes/${mailBoxId}/templates/${templateName}`)
    commit("UPDATE_MAIL_BOX", { mailBox: res.data, index })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const deleteMailBoxTemplate = async ({ commit }, { mailBoxId, templateName, index }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.delete(`/rest/mailboxes/${mailBoxId}/templates/${templateName}`)
    commit("UPDATE_MAIL_BOX", { mailBox: res.data, index })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const addMailBoxAddressBook = async ({ commit }, { mailBoxId, addressBook, index }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.post(`/rest/mailboxes/${mailBoxId}/addressbooks`, addressBook)
    commit("ADD_MAILBOX_ADDRESS_BOOK", { addressBook: res.data, index })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const deleteMailBoxAddressBook = async (
  { commit },
  { mailBoxId, bookId, index, addressBookIndex }
) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.delete(`/rest/mailboxes/${mailBoxId}/addressbooks/${bookId}`)
    commit("DELETE_MAILBOX_ADDRESS_BOOK", { index, addressBookIndex })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

export default {
  getMailBoxes,
  addMailBox,
  updateMailBox,
  deleteMailBox,
  addActivityType,
  deleteActivityType,
  addMailBoxScript,
  deleteMailBoxScript,
  addMailBoxTemplate,
  deleteMailBoxTemplate,
  addMailBoxAddressBook,
  deleteMailBoxAddressBook,
  updateActivityType
}
