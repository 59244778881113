<template>
  <div>
    <v-dialog
      v-model="dialog.visible"
      :type="dialog.config.type"
      :max-width="dialog.config.maxWidth"
      transition="scale-transition"
      persistent
      @keydown.esc="cancel"
    >
      <v-card :color="dialog.config.color" :dark="dialog.dark">
        <v-card-title>{{ dialog.title }}</v-card-title>
        <v-card-text>
          <v-row v-if="!dialog.showInputText" no-gutters>
            <v-col v-html="getMessage"> </v-col>
          </v-row>
          <v-row v-else no-gutters>
            <v-col>
              <v-textarea
                v-model="dialog.inputText"
                :label="getMessage"
                rows="3"
                autofocus
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="!dialog.config.customOptions">
          <v-spacer></v-spacer>
          <!--v-btn text :disabled="disableAccept" @click="accept">Aceptar</v-btn-->
          <DialogButton
            :disabled="disableAccept"
            role="accept"
            @click="accept"
            :color="dialog.dark ? 'white' : 'black'"
          />
          <!--v-btn v-if="dialog.showCancelButton" text @click="cancel">Cancelar</v-btn-->
          <DialogButton
            v-if="dialog.showCancelButton"
            role="cancel"
            @click="cancel"
            :color="dialog.dark ? 'white' : 'black'"
          />
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <DialogButton
            v-for="(btn, index) in dialog.config.customOptions"
            :key="index"
            @click="custom(btn.value)"
            :text="btn.text"
            :role="btn.role"
            :color="dialog.dark ? 'white' : 'black'"
          />
          <!--v-btn v-for="btn in dialog.config.customOptions" text @click="custom(btn.value)">{{
            btn.text
          }}</v-btn-->
          <!--v-btn v-if="dialog.showCancelButton" text @click="cancel">Cancelar</v-btn-->
          <DialogButton role="cancel" @click="cancel" :color="dialog.dark ? 'white' : 'black'" />
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="notification.visible" :timeout="2500">
      {{ notification.message }}
      <DialogButton
        buttonClass="float-right"
        role="close"
        @click="closeNotification"
        color="pink"
      />
      <!--v-btn
        class="float-right"
        color="pink"
        notification.message
        @click="notification.visible = false"
      >
        Cerrar
      </v-btn-->
    </v-snackbar>
  </div>
</template>

<script>
  import DialogButton from "./DialogButton"

  export default {
    name: "dialogs",
    data() {
      return {
        dialog: {
          showCancelButton: true,
          showInputText: false,
          required: false,
          dark: true,
          visible: false,
          resolve: null,
          reject: null,
          title: null,
          message: null,
          config: {
            maxWidth: 500,
            color: "white"
          },
          inputText: null
        },
        notification: {
          visible: false,
          message: null,
          resolve: null,
          reject: null
        }
      }
    },
    computed: {
      disableAccept() {
        return (
          this.dialog.showInputText &&
          (this.dialog.inputText == null || this.dialog.inputText.length == 0)
        )
      },
      getMessage() {
        return this.dialog.message != null ? this.dialog.message.split("\n").join("<br/>") : null
      }
    },
    methods: {
      reset() {
        this.dialog = {
          showCancelButton: true,
          showInputText: false,
          required: false,
          dark: true,
          visible: false,
          resolve: null,
          reject: null,
          title: null,
          message: null,
          config: {
            maxWidth: 500,
            color: "white"
          },
          inputText: null
        }
      },
      resetNotification() {
        this.notification = {
          visible: false,
          message: null,
          resolve: null,
          reject: null
        }
      },
      show(message, title, config) {
        this.dialog.message = message || ""
        this.dialog.title = title || ""
        this.dialog.config = Object.assign(this.dialog.config, config)
        this.dialog.visible = true
        return new Promise((resolve, reject) => {
          this.dialog.resolve = resolve
          this.dialog.reject = reject
        })
      },
      showNotification(message) {
        this.notification.message = message
        this.notification.visible = true
        return new Promise((resolve, reject) => {
          this.notification.resolve = resolve
          this.notification.reject = reject
        })
      },
      showMessage(message, title = "Atención", config = { color: "error" }) {
        this.dialog.showCancelButton = false
        //this.dialog.showInputText = false
        //this.dialog.dark = true
        return this.show(message, title, config)
      },
      showDialog(message, title, config) {
        //this.dialog.showCancelButton = true
        //this.dialog.showInputText = false
        //this.dialog.dark = true
        return this.show(message, title, config)
      },
      showError(error) {
        this.dialog.showCancelButton = false
        //this.dialog.showInputText = false
        //this.dialog.dark = true
        let msg = "Se ha producido un error"
        if (typeof error === "string") {
          msg = error || msg
        } else if (error.response) {
          msg = error.response.data.message || msg
        } else if (error.data) {
          msg = error.data.message || msg
        } else if (error) {
          msg = error.message || msg
        }
        return this.show(msg, "Error", {
          color: "error"
        })
      },
      showPrompt(message, title, required, config) {
        //this.dialog.showCancelButton = true
        this.dialog.showInputText = true
        this.dialog.dark = false
        this.dialog.required = required
        return this.show(message, title, config)
      },
      accept() {
        if (this.dialog.required && this.dialog.inputText == null) {
          return
        }
        this.dialog.resolve(this.dialog.showInputText ? this.dialog.inputText || null : true)
        this.reset()
      },
      cancel() {
        this.dialog.resolve(false)
        this.reset()
      },
      custom(value) {
        this.dialog.resolve(value)
        this.reset()
      },
      closeNotification() {
        //this.notification.visible = false
        this.notification.resolve()
        this.resetNotification()
      }
    },
    components: {
      DialogButton
    }
  }
</script>
