/*const mailSort = (a, b) =>
  a.priority < b.priority
    ? -1
    : a.priority > b.priority
    ? 1
    : a.sentDate < b.sentDate
    ? 1
    : a.sentDate > b.sentDate
    ? -1
    : 0*/

export default {
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_UPDATING(state, value) {
    state.updating = value
  },
  SET_LOADING_CONTENT(state, value) {
    state.loadingContent = value
  },
  SET_MAIL_BOXES(state, value) {
    state.mailBoxes = value
  },
  SET_MAILS(state, value) {
    state.mailCount = value.total
    state.mails = value.items
  },
  SET_DRAFTS(state, values) {
    const drafts = []
    values.forEach(value => {
      const json = JSON.parse(value.activityDraft.draft)
      value.json = json
      drafts.push(value)
    })
    state.drafts = drafts
  },
  SET_UNASSIGNED_MAIL_COUNT(state, value) {
    state.unassignedMailCount = value
  },
  /*ASSIGN_INBOUND_MAIL(_state, _value) {
    console.log("assign inbound mail", value)
  },*/
  /*UNASSIGN_INBOUND_MAIL(_state, _value) {
    console.log("unassign inbound mail", value)
  },*/
  /*UPDATE_INBOUND_MAIL(_state, _value) {
    console.log("edit inbound mail", _value)
  },*/
  DELETE_INBOUND_MAIL(state, index) {
    state.mails.splice(index, 1)
  },
  UPDATE_ACTIVITY(state, { activity, index, boxType }) {
    if (index === null || index === undefined || index === -1) {
      state.mail.activity = activity
    } else {
      if (boxType === "drafts") {
        const draft = state.drafts[index]
        draft.activity = Object.assign({}, draft.activity, activity)
        draft.pauseTime = activity.pauseTime
        draft.waitTime = activity.waitTime
        draft.workTime = activity.workTime
        draft.status = activity.status
        draft.json.activity = Object.assign({}, draft.json.activity, activity)
        /*state.drafts[index] = Object.assign(
          {},
          JSON.parse(JSON.stringify(state.drafts[index])),
          JSON.parse(JSON.stringify(draft))
        )
        console.log("FINAL DRAFT", JSON.stringify(state.drafts[index]))*/
        //state.mail = Object.assign({}, state.mail, { activity: draft.activity })
        state.mail.activity = Object.assign({}, state.mail.activity, draft.activity)
        state.mail = Object.assign({}, JSON.parse(JSON.stringify(state.mail)))
      } else {
        state.mails[index].activity = activity
      }
    }
  },
  SET_MAIL_CONTENT(state, value) {
    state.mailContent = value
  },
  SET_MAIL_CONTENT_ERROR(state, value) {
    state.mailContent = {
      error: value.data || { status: 0, message: "Error obteniendo contenido del E-mail" }
    }
  },
  SET_MAIL(state, value) {
    state.mail = value
  },
  UPDATE_MAIL(state, value) {
    state.mail = Object.assign(state.mail, value)
  },
  SET_MAIL_ACTIVITY_FOR_DRAFT(state, { activity, emailAccountId }) {
    state.mail = {
      emailAccountId,
      activity
    }
    state.drafts.push
  },
  UPDATE_DRAFT(state, { index, value, boxType = null, draft = null }) {
    if (index !== null && index !== undefined && index > -1) {
      if (boxType === "drafts") {
        if (!value) {
          state.drafts.splice(index, 1)
        } else {
          state.drafts[index].activityDraft = {
            draft: draft.draft,
            draftId: draft.draftId
          }
          const json = JSON.parse(draft.draft)
          state.drafts[index].json.from = json.from
          state.drafts[index].json.to = json.to
          state.drafts[index].json.bcc = json.bcc
          state.drafts[index].json.cc = json.cc
          state.drafts[index].json.replyTo = json.replyTo
          state.drafts[index].json.contentType = json.contentType
          state.drafts[index].json.inReplyTo = json.inReplyTo
          state.drafts[index].json.parts = json.parts
          state.drafts[index].json.subject = json.subject
        }
      } else {
        state.mails[index].activity.hasDraft = value
      }
    } else if (index === -1) {
      //state.mail = null
    }
  },
  SET_EMAIL_ACCOUNTS(state, value) {
    state.emailAccounts = value
  }
}
