export default {
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_UPDATING(state, value) {
    state.updating = value
  },
  SET_ADDRESSES(state, value) {
    state.emailAddresses = value
  },
  ADD_ADDRESS(state, value) {
    state.emailAddresses.push(value)
  },
  UPDATE_ADDRESS(state, { emailAddress, index }) {
    Object.assign(state.emailAddresses[index], emailAddress)
  },
  REMOVE_ADDRESS(state, index) {
    state.emailAddresses.splice(index, 1)
  },
  SET_MAIL_GROUPS(state, value) {
    state.emailGroups = value
  },
  ADD_MAIL_GROUP(state, value) {
    state.emailGroups.push(value)
  },
  UPDATE_MAIL_GROUP(state, { emailGroup, index }) {
    Object.assign(state.emailGroups[index], emailGroup)
  },
  REMOVE_MAIL_GROUP(state, index) {
    state.emailGroups.splice(index, 1)
  },
  SET_ADDRESS_BOOKS(state, value) {
    state.addressBooks = value
  },
  ADD_ADDRESS_BOOK(state, value) {
    state.addressBooks.push(value)
  },
  UPDATE_ADDRESS_BOOK(state, { addressBook, index }) {
    Object.assign(state.addressBooks[index], addressBook)
  },
  REMOVE_ADDRESS_BOOK(state, index) {
    state.addressBooks.splice(index, 1)
  }
}
