import Vue from "vue"

const getActivityDocumentTypes = async ({ commit }, serviceId) => {
  const params = {}
  params.serviceId = serviceId
  try {
    commit("SET_LOADING", true)
    const res = await Vue.axios.get(`/rest/${serviceId}/activities/documents/types`)
    commit("SET_ACTIVITY_DOCUMENT_TYPES", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const addActivityDocumentType = async ({ commit }, { activityDocumentType, serviceId }) => {
  try {
    commit("SET_LOADING", true)
    const res = await Vue.axios.post(
      `/rest/${serviceId}/activities/documents/types`,
      activityDocumentType
    )
    commit("ADD_ACTIVITY_DOCUMENT_TYPE", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const updateActivityDocumentType = async (
  { commit },
  { activityDocumentType, index, serviceId }
) => {
  try {
    commit("SET_LOADING", true)
    const res = await Vue.axios.put(
      `/rest/${serviceId}/activities/documents/types/${activityDocumentType.documentTypeId}`,
      activityDocumentType
    )
    commit("UPDATE_ACTIVITY_DOCUMENT_TYPE", { activityDocumentType: res.data, index })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const deleteActivityDocumentType = async ({ commit }, { activityDocumentType, index }) => {
  try {
    commit("SET_LOADING", true)
    const res = await Vue.axios.delete(
      `/rest/${activityDocumentType.serviceId}/activities/documents/types/${activityDocumentType.documentTypeId}`
    )
    commit("REMOVE_ACTIVITY_DOCUMENT_TYPE", index)
    return Promise.resolve(activityDocumentType)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

export default {
  getActivityDocumentTypes,
  addActivityDocumentType,
  updateActivityDocumentType,
  deleteActivityDocumentType
}
