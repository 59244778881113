import Vue from "vue"

const clearScriptFilter = ({ commit }) => {
  commit("SET_SCRIPT_FILTER", [])
}

const getScripts = async ({ commit }, params) => {
  try {
    commit("SET_LOADING", true)
    const res = await Vue.axios.get("/rest/scripts", { params })
    commit("SET_SCRIPTS", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const addScript = async ({ commit }, script) => {
  try {
    commit("SET_UPDATING", true)
    const formData = new FormData()
    formData.append("form", script.uploadDocument)
    formData.append("name", script.name)
    formData.append("serviceid", script.serviceId)
    formData.append("useincall", script.useInCall ? "Y" : "N")
    formData.append("useinemail", script.useInEmail ? "Y" : "N")
    formData.append("useinbackoffice", script.useInBackOffice ? "Y" : "N")
    const res = await Vue.axios({
      method: "POST",
      url: "/rest/scripts",
      timeout: 1000 * 60 * 3,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData
    })
    commit("ADD_SCRIPT", res.data)
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const updateScript = async ({ commit }, { script, index }) => {
  try {
    commit("SET_UPDATING", true)
    const res = await Vue.axios.put(`/rest/scripts/${script.scriptId}`, script)
    commit("UPDATE_SCRIPT", { script: res.data, index })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const deleteScript = async ({ commit }, { script, index }) => {
  try {
    commit("SET_UPDATING", true)
    await Vue.axios.delete(`/rest/scripts/${script.scriptId}`)
    commit("REMOVE_SCRIPT", index)
    return Promise.resolve(script)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

const downloadDoc = async ({ commit }, { scriptUri, token }) => {
  try {
    commit("SET_LOADING", true)
    const finalScriptUri = scriptUri.replace("/api-docs", "")
    const parts = `${process.env.VUE_APP_API_DOCS_URL}${finalScriptUri}`.split("?")
    const form = window.document.createElement("form")
    form.method = "GET"
    form.action = parts[0]
    form.target = "_blank"

    for (let part of parts[1].split("&")) {
      const param = window.document.createElement("input")
      param.setAttribute("type", "hidden")
      param.setAttribute("name", part.split("=")[0])
      param.setAttribute("value", decodeURIComponent(part.split("=")[1]))
      form.appendChild(param)
    }

    const param = window.document.createElement("input")
    param.setAttribute("type", "hidden")
    param.setAttribute("name", "token")
    param.setAttribute("value", token)
    form.appendChild(param)

    window.document.body.appendChild(form)
    form.submit()
    window.document.body.removeChild(form)
    return Promise.resolve()
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_LOADING", false)
  }
}

const uploadFile = async ({ commit }, { index, form, scriptId }) => {
  try {
    commit("SET_UPDATING", true)
    const formData = new FormData()
    formData.append("form", form)
    const res = await Vue.axios({
      method: "PUT",
      url: `/rest/scripts/file/${scriptId}`,
      timeout: 1000 * 60 * 3,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData
    })
    commit("UPLOAD_FILE", { script: res.data, index })
    return Promise.resolve(res.data)
  } catch (error) {
    return Promise.reject(error.response || error)
  } finally {
    commit("SET_UPDATING", false)
  }
}

export default {
  clearScriptFilter,
  getScripts,
  addScript,
  updateScript,
  deleteScript,
  downloadDoc,
  uploadFile
}
