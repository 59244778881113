export default {
  namespaced: true,
  actions: {
    showNotification({ commit }, message) {
      return this.$app.$dialogs.showNotification(message)
    },
    showError({}, error) {
      return this.$app.$dialogs.showError(error)
    },
    showWarning({ commit }, message) {
      return this.$app.$dialogs.showMessage(message, "Atención", { color: "warning" })
    },
    showDialog({}, message) {
      return this.$app.$dialogs.showDialog(message, "Atención", { color: "warning" })
    },
    showMessage({ commit }, { message, title, config }) {
      return this.$app.$dialogs.showDialog(message, title, config)
    },
    showPrompt(
      { commit },
      { message, title = "Atención", required = true, config = { color: "white" } }
    ) {
      return this.$app.$dialogs.showPrompt(message, title, required, config)
    }
  }
}
