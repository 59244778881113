const mailBox = state => id => state.mailBoxes.find(mb => mb.id == id) || {}

const mailBoxByAccountId = state => accountId =>
  state.mailBoxes.find(
    mb => mb.inboundAccountId == accountId || mb.outboundAccountId == accountId
  ) || {}

const mailBoxByEmailAddress =
  state =>
  ({ emailAddress, boxType }) =>
    state.mailBoxes.find(
      mb =>
        (mb.inboundEmailAddress === emailAddress && boxType === "inbound") ||
        (mb.outboundEmailAddress === emailAddress && boxType === "outbound")
    ) || {}

const isAssigned = _state => mail => mail.activity && mail.activity.userId

const priorityValue = state => id => state.priorities.find(p => p.id === id) || {}

const emailAccountOutboundAddresses = state => emailAccountId => {
  const addresses = []
  const emailAccount = state.emailAccounts.find(
    account => account.emailAccountId === emailAccountId
  )
  if (emailAccount) {
    //addresses.push(emailAccount.emailAccount)
    addresses.push(emailAccount.emailAddress)
    if (emailAccount.emailAlternates) {
      addresses.push(...emailAccount.emailAlternates.split(/,|;/))
    }
  }

  return [...new Set(addresses)]
}

export default {
  mailBox,
  mailBoxByAccountId,
  mailBoxByEmailAddress,
  isAssigned,
  priorityValue,
  emailAccountOutboundAddresses
}
