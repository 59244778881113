import DOMPurify from "dompurify"

const STYLE_REGEX = new RegExp("<style[^>]*>([\\s\\S]*?)<\\/?\\w+>", "gi")
const TAGS_REGEX = new RegExp("[^(\\.|\\#|\\w|\\-)](body|html|div|\\*)( |\\,|\\[|\\s)", "gis")

const htmlSanitize = part => {
  if (part.contentType && part.contentType.toLowerCase().indexOf("text/html") > -1) {
    if (part.content) {
      part.content = DOMPurify.sanitize(part.content)

      const match = part.content.match(STYLE_REGEX)
      if (match) {
        match.forEach(item => {
          let cleanItem = item.replaceAll(TAGS_REGEX, "")
          part.content = part.content.replaceAll(item, cleanItem)
        })
      }
    }
  }
  if (Array.isArray(part.parts)) {
    part.parts.forEach(p => {
      p = htmlSanitize(p)
    })
  }
  return part
}

export default htmlSanitize
