const avayaLlucHost = process.env.VUE_APP_WSK_AVAYALLUC_URL

const connect = ({ commit }, { pbx, terminal, token }) => {
  try {
    commit("SET_LOADING", true)
    let client = null
    switch (pbx) {
      case "avayalluc":
        client = new WebSocket(`${avayaLlucHost}/events?terminal=${terminal}&token=${token}`)
        break
      default:
        throw "PBX no válido: " + pbx
    }
    client.onopen = data => {
      console.log("open", data)
    }
    client.onmessage = data => {
      console.log("message", data)
    }
    client.onerror = data => {
      console.log("error", data)
    }
    client.onclose = data => {
      console.log("close", data)
    }
    commit("SET_CLIENT", { pbx, client })
  } finally {
    commit("SET_LOADING", false)
  }
}

const close = async ({ commit }) => {
  console.log("close")
}

export default {
  connect,
  close
}
