<template>
  <v-tooltip top :disabled="tooltip == null" v-model="show">
    <template v-slot:activator="{}">
      <v-btn
        :class="(buttonClass || '') + ' px-2'"
        :color="color"
        :loading="loading"
        :disabled="disabled"
        text
        rounded
        :type="type"
        @click="$emit('click', $event)"
        @mouseover="mouseover"
        @mouseleave="mouseleave"
        @mousedown="mouseleave"
      >
        <v-icon v-if="icon || config[role].icon" class="mr-1">{{
          icon || config[role].icon
        }}</v-icon>
        <slot>{{ text || config[role].text }}</slot>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
  export default {
    data() {
      return {
        show: false,
        config: {
          default: {
            icon: null,
            text: "Acción"
          },
          close: {
            icon: "mdi-close",
            text: "Cerrar"
          },
          cancel: {
            icon: "mdi-close",
            text: "Cancelar"
          },
          accept: {
            icon: "mdi-check",
            text: "Aceptar"
          },
          save: {
            icon: "mdi-check",
            text: "Guardar"
          },
          create: {
            icon: "mdi-check",
            text: "Crear"
          },
          update: {
            icon: "mdi-update",
            text: "Actualizar"
          },
          delete: {
            icon: "mdi-delete",
            text: "Eliminar"
          },
          refresh: {
            icon: "mdi-refresh",
            text: "Refrescar"
          },
          search: {
            icon: "mdi-magnify",
            text: "Buscar"
          },
          clear: {
            icon: "mdi-close",
            text: "Limpiar"
          },
          download: {
            icon: "mdi-download",
            text: "Descargar"
          },
          settings: {
            icon: "mdi-cog",
            text: "Configuración"
          },
          return: {
            icon: "mdi-arrow-u-left-bottom",
            text: "Volver"
          },
          preview: {
            icon: "mdi-file-eye",
            text: "Previsualizar"
          },
          runPolling: {
            icon: "mdi-play-outline",
            text: "Encender"
          },
          shutdown: {
            icon: "mdi-power",
            text: "Apagar"
          }
        }
      }
    },
    props: {
      role: {
        type: String,
        default: "default"
      },
      type: {
        type: String,
        default: "button"
      },
      icon: String,
      text: String,
      loading: Boolean,
      disabled: Boolean,
      tooltip: String,
      color: {
        type: String,
        default: "blue darken-1"
      },
      buttonClass: {
        type: String,
        default: null
      }
    },
    methods: {
      mouseover() {
        this.show = true
      },
      mouseleave() {
        this.show = false
      }
    }
  }
</script>
